/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../../../Assets/icons/csv.png";
import usePermissions from "../../../../../components/permissions/permission";
import Papa from "papaparse";

const Opening_Price_Quo_Form_List = ({ itemReq }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItemReq, setselectedItemReq] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchItemData();
  }, []);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/get-openingcommittee-uploaded-quotation-PRdata`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setItems(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  // Function to filter the table based on search input
  useEffect(() => {
    const filtered = items.filter(
      (item) =>
        item.pr_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.department_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(filtered);
    setCurrentPage(1); // Reset to first page when searching
  }, [searchQuery, items]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleViewClick = (itemReq) => {
    // Navigate to the new page and pass the object
    navigate("/committess/openingPriceFormQuoLists", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const exportToCSV = () => {
    if (!Array.isArray(filteredItems) || filteredItems.length === 0) {
      alert("No data available to export.");
      return;
    }

    const csvData = filteredItems.map((itemReq) => ({
      "Purchase Request No": itemReq.pr_id,
      "Purchase Request Title": itemReq.title,
      Department: itemReq.department_name,
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Opening_Price_Quo_Form_List.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Opening Price Quotation List
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
          <input
            type="text"
            placeholder="Search by PR No, Title, or Department"
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-gray-400 px-2 py-1"
          />
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Purchase Request No</th>
              <th className="text-primary px-6 py-4">Title</th>
              <th className="text-primary px-6 py-4">Department</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems.length > 0 ? (
              currentItems.map((itemReq) => (
                <tr key={itemReq.id}>
                  <td className="bg-white px-6 py-4 text-center">
                    {itemReq.pr_id}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {itemReq.title}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {itemReq.department_name}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {hasPermission(2980) && (
                      <button
                        className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                        onClick={() => handleViewClick(itemReq)}
                      >
                        View
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  className="text-center py-4 text-gray-500 font-semibold"
                >
                  No matching results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`px-4 py-2 mx-1 rounded-md ${
              currentPage === index + 1 ? "bg-gray-300" : "bg-white"
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
          <div className="flex items-center" onClick={exportToCSV}>
            <div onClick={exportToCSV} className="font-semibold">
              Export CSV
            </div>
            <div>
              <img className="h-8 w-8" src={CSV} alt="logo-csv" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Opening_Price_Quo_Form_List;
