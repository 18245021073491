import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";

const EditManualDisposeRecord = ({ togglePopup, resetTable, row }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;

  const [sampleDetails, setSampleDetails] = useState({
    dispose_id: "",
    sample_id: "",
    tested_date: "",
    disposed_date: "",
    disposal_remark: "",
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  // set sample  details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSampleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

   // Handle Notification Close
   const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

   // Edit Form
   const handleEditForm = async () => {

    console.log(sampleDetails)
    try {
      const response = await axios.put(
        `${backendUrl}/qulaityDisposals/editDisposedSamples/${sampleDetails.dispose_id}`,
        sampleDetails,
       
      );

      
      setNotification({
        message: "Sample details Edit successfully!",
        type: "success",
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);

      setNotification({
        message: error.response.data,
        type: "error",
      });
    }
    setIsSaveConfirmationOpen(false);
  };


  // use State Reset
  const handleResetForm = () => {
    setSampleDetails({
      // dispose_id: "",
      // sample_id: "",
      tested_date: "",
      disposed_date: "",
      disposal_remark: "",
    });
    setIsResetConfirmationOpen(false);
  };

  useEffect(() => {
  if (row) {
    const formattedRow = {
      ...row,
      tested_date: row.tested_date ? row.tested_date.split("T")[0] : "",
      disposed_date: row.disposed_date ? row.disposed_date.split("T")[0] : "",
    };
    setSampleDetails(formattedRow);
  }
}, [row]);


 
  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Sample Disposal</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Sample Type */}
              <div>
                <label className="block text-gray-700">Dispose ID*</label>
                <input
                  type = "text"
                  name="dispose_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.dispose_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

              {/* Sample ID */}
              <div>
                <label className="block text-gray-700">Sample ID*</label>
                <input
                  type="text"
                  name="sample_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
               {/* Tested date */}
               <div>
                <label className="block text-gray-700">Tested date*</label>
                <input
                  type="date"
                  name="tested_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.tested_date	}
                  onChange={handleInputChange}
                />
              </div>
                {/* Expiry date */}
              <div>
                <label className="block text-gray-700">Disposed date*</label>
                <input
                  type="date"
                  name="disposed_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.disposed_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* Disposal remark */}
              <div>
                <label className="block text-gray-700">Disposal remark*</label>
                <input
                  type="text"
                  name="disposal_remark"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.disposal_remark}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleEditForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditManualDisposeRecord;
