import axios from "axios";
import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";

const Edit_sample_testing = ({ togglePopup, row }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [notification, setNotification] = useState({ message: "", type: "" });

  const [sampleDetails, setSampleDetails] = useState({
    sample_type: "",
    sample_id: "",
    received_date: "",
    sample_test_id: "",
    result: "",
    qualityStages: [],
  });

  const [stage, setStage] = useState([{ test_stage: "" }]);
  const [filteredSampleType, setFilteredSampleType] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

  // Populate state with row data when component mounts or row changes
  useEffect(() => {
    if (row) {
      setSampleDetails({
        sample_type: row.sample_type || "",
        sample_id: row.sample_id || "",
        received_date: row.received_date
          ? new Date(row.received_date).toISOString().split("T")[0]
          : "",
        sample_test_id: row.sample_test_id || "",
        result: row.result || "",
        qualityStages: row.quality_test_stages || [],
      });
      setStage(
        row.quality_test_stages?.map((stage) => ({
          test_stage: stage.test_stage || "",
          percentage: stage.percentage || "",
          remark: stage.remark || "",
        })) || [{ test_stage: "" }]
      );
    }
  }, [row]);

  // Handle input change for sample details and stages
  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;

    if (index !== null) {
      // Updating a specific testing stage
      const newStages = [...stage];
      newStages[index][name] = value;
      setStage(newStages);
    } else {
      // Updating sample details
      setSampleDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Save Form
  const handleSaveForm = async () => {
    try {
      const requestBody = {
        sample_id: sampleDetails.sample_id,
        sample_type: sampleDetails.sample_type,
        sample_test_id: sampleDetails.sample_test_id,
        received_date: sampleDetails.received_date,
        result: sampleDetails.result,
        production_id: row?.production_id || "",  
        production_name: row?.production_name || "", 
        qualityStages: stage.map((s) => ({
          test_stage: s.test_stage,
          percentage: s.percentage,
          remark: s.remark,
        })),
      };
  
      console.log("Request Payload:", requestBody);
  
      const response = await axios.put(
        `${backendUrl}/qualitySampleTest/updateSampleTesting/${sampleDetails.sample_test_id}`,
        requestBody
      );
  
      setNotification({
        message: "Sample details saved successfully!",
        type: "success",
      });
  
    } catch (error) {
      console.error(error);
      setNotification({
        message: "Error saving sample details. Please try again.",
        type: "error",
      });
    }
    setIsSaveConfirmationOpen(false);
  };
  
      // Handle Notification Close
      const handleCloseNotification = () => {
        setNotification({ message: "", type: "" });
      };
  

  // Reset Form
  const handleResetForm = () => {
    setSampleDetails({
      sample_type: "",
      sample_id: "",
      received_date: "",
      sample_test_id: "",
      result: "",
      qualityStages: [],
    });
    setStage([{ test_stage: "" }]);
    setIsResetConfirmationOpen(false);
  };

  // Add a new testing stage
  const addStage = () => {
    setStage([...stage, { test_stage: "" }]);
  };

  // Remove a testing stage
  const removeStop = (index) => {
    const newStages = stage.filter((_, i) => i !== index);
    setStage(newStages);
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Sample Testing</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Sample ID */}
              <div>
                <label className="block text-gray-700">Sample ID*</label>
                <input
                  type="text"
                  name="sample_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_id}
                  onChange={handleInputChange}
                />
              </div>

              {/* Sample Type */}
              <div>
                <label className="block text-gray-700">Sample*</label>
                <select
                  name="sample_type"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Sample Type</option>
                  <option value="BULK">Bulk</option>
                  <option value="TENDER">Tender</option>
                </select>
              </div>

              {/* Sample Testing ID */}
              <div>
                <label className="block text-gray-700">
                  Sample Testing ID*
                </label>
                <input
                  type="text"
                  name="sample_test_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.sample_test_id}
                  onChange={handleInputChange}
                />
              </div>

              {/*Tender/ Bulk received date */}
              {/* Tender/ Bulk received date */}
              <div>
                <label className="block text-gray-700">
                  Tender/ Bulk received date*
                </label>
                <input
                  type="date"
                  name="received_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.received_date}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <hr className="mt-10" />

          {/* stage */}
          {/* Stage fields */}
          {stage.map((stop, index) => (
            <div key={index} className="relative mb-4">
              <div className="flex items-center">
                <label className="block mb-2 mr-2 text-gray-700">
                  Testing Stage {index + 1}
                </label>
                <button
                  type="button"
                  className="absolute top-0 right-0 mt-2 mr-2 font-semibold text-red-600 hover:text-red-800"
                  onClick={() => removeStop(index)}
                >
                  <IoIosCloseCircleOutline />
                </button>
              </div>

              {/* Flex container for side-by-side layout */}
              <div className="flex space-x-4">
                {/* Sample Testing Stage */}
                <div className="w-1/3">
                  <label className="block text-gray-700">Testing Stage</label>
                  <input
                    type="text"
                    name="test_stage"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={stop.test_stage}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>

                {/* Testing Stage Percentage */}
                <div className="w-1/3">
                  <label className="block text-gray-700">
                    Testing Stage Percentage
                  </label>
                  <input
                    type="text"
                    name="percentage"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={stop.percentage}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>

                {/* Remark */}
                <div className="w-1/3">
                  <label className="block text-gray-700">Remark</label>
                  <input
                    type="text"
                    name="remark"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={stop.remark}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={addStage}
            className="p-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Add Stage
          </button>

          <hr className="mt-10" />

          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Result* */}
              <div>
                <label className="block text-gray-700">Result*</label>
                <select
                  type="text"
                  name="result"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={sampleDetails.result}
                  onChange={handleInputChange}
                >
                  <option value="">Select Result</option>
                  <option value="PASS">Pass</option>
                  <option value="FAIL">Fail</option>
                </select>
              </div>
            </div>
          </div>
        </form>

        {/* Save/ Reset */}
        <div className="flex justify-end mt-6 space-x-4">
          <button
            type="button"
            onClick={handleOpenResetConfirmation}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
          >
            Reset
          </button>
          <button
            type="button"
            onClick={handleOpenSaveConfirmation}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
          >
            Save
          </button>
        </div>

        {/* Confirmation modals */}
        {isResetConfirmationOpen && (
          <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
            <div className="w-1/3 p-6 bg-white rounded-lg shadow-lg">
              <p className="mb-4 text-lg font-medium">
                Are you sure you want to reset the form?
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={handleResetForm}
                  className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={handleCloseResetConfirmation}
                  className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
            <div className="w-1/3 p-6 bg-white rounded-lg shadow-lg">
              <p className="mb-4 text-lg font-medium">
                Are you sure you want to save the changes?
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={handleSaveForm}
                  className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={handleCloseSaveConfirmation}
                  className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Edit_sample_testing;
