import React, { useEffect, useState } from "react";
import moment from "moment";

import { IoIosCloseCircleOutline } from "react-icons/io";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import Notification from "../../../../components/notification/notification";


const  BreakdownRecordsPopup = ({ togglePopup }) => {

  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;

  const backendUrl2 = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  // const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;
  // const { REACT_APP_BACKEND_URL } = process.env;


  const currentDate = moment().format("YYYY-MM-DD");
  const [driverDetails, setDriverDetails] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const [driverMap, setDriverMap] = useState({});


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [breakDown, setBreakDown] = useState({
    breakdown_id: "",
    breakdown_date: "",
    breakdown_time: "",
    breakdown_location: "",
    remark: "",
    vehicle_id: "",
    driver_id: "",
  });
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [vehicleMap, setVehicleMap] = useState({});

    // Define a function to fetch Driver IDs
    const fetchDriverDetails = async () => {
      try {
        const response = await axios.get(`${backendUrl}/driver/get-details`);
  
        // Ensure response contains the 'data' property before setting state
        const vehicles = response.data?.data || [];
  
        setDriverDetails(vehicles);
        console.log("Vehicle Details Fetched:", vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

  const handleOpenModal = () => {
    if (uploadedFiles.length <= 0) {
      setIsModalOpen(true);
    }
  }


  // Define a function to fetch vehicle IDs
  const fetchVehicleDetails = async () => {
    try {
      const response = await axios.get(`${backendUrl}/vehicle/allVehicles`);

      // Ensure response contains the 'data' property before setting state
      const vehicles = response.data?.data || [];

      setVehicleDetails(vehicles);
      console.log("Vehicle Details Fetched:", vehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDriverID = async () => {
    try {
      console.log("test 1");
      const response = await fetch(
        `${backendUrl}/driver/get-all-drivers-employee-number`
      );
      const data = await response.json();

      console.log("------");
      console.log(data);
      console.log("------");

      const numbers = data.map((item) => item.employee_no);
      setEmployeeNumbers(numbers);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    getDriverID();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "employeeNumber" && value.length > 0) {
      const filtered = employeeNumbers.filter((emp) =>
        emp.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmployees(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  

  const handleSuggestionClick = (suggestion) => {
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      employeeNumber: suggestion,
    }));
    setShowSuggestions(false);
    fetchDriverDetails(suggestion);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles([]);
  };

 
  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {


    try {
      const response = await axios.post(`${backendUrl}/breakdownRec`, breakDown, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setNotification({
        message: response.data,
        type: "success",
      });
  
      console.log('Breakdown saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving breakdown:', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 400) {
        // Safely access error messages by checking if they exist
        const errorMessage = (error.response.data[0] && error.response.data[0].msg) || error.response.data.error || "An unexpected error occurred";
        
        setNotification({
          message: errorMessage,
          type: "error",
        });
      }
    
    }

    setIsSaveConfirmationOpen(false);
  };

    // Handle Notification Close
    const handleCloseNotification = () => {
      setNotification({ message: "", type: "" });
    };
  

  const handleResetForm = () => {
    setBreakDown({
      breakdown_id: "",
      breakdown_date: "",
      breakdown_time: "",
      breakdown_location: "",
      remark: "",
      vehicle_id: "",
      driver_id: "",
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  // const handleVehicleIDChange = (selectedValue) => {
  //   const vehicle = vehicleMap[selectedValue];
  //   if (vehicle) {
  //     setBreakDown((prevDetails) => ({
  //       ...prevDetails,
  //       vehicle_id: vehicle.vehicle_id,
  //     }));
  //   }
  // };

    const handleVehicleIDChange = (value) => {
    // Find the selected vehicle object
    const selectedVehicle = vehicleDetails.find(
      (vehicle) => vehicle.vehicle_id === value
    );

    // Update state with the selected vehicle ID and its number plate (optional)
    setBreakDown((prev) => ({
      ...prev,
      vehicle_id: value,
      // vehicle_no_plate_no: selectedVehicle?.vehicle_no_plate_no || "No Plate",
    }));
  };

  const LocationChange = (fieldName, place) => {
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      breakdown_location: place.label, // store the place label
    }));
  };

  const handleDriverIDChange = (value) => {
   

    // Update state with the selected vehicle ID and its number plate (optional)
    setBreakDown((prev) => ({
      ...prev,
      driver_id: value,
      // vehicle_no_plate_no: selectedVehicle?.vehicle_no_plate_no || "No Plate",
    }));
  };

  const getLastBrackDown =  async ()  => {
    try {
      const response = await axios(
        `${backendUrl}/breakdownRec/lastID`
      );

      console.log(response.data.lastBreakDownId);

      setBreakDown((prevDetails) => ({
        ...prevDetails,
        breakdown_id : response.data.lastBreakDownId,
        
      }));
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  }

  useEffect(() => {
    fetchVehicleDetails();
    fetchDriverDetails();
    getLastBrackDown();
  }, []);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Breakdown Record</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>


        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/*  Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID*</label>
                <select
        name="vehicle_id"
        className="w-full p-2 mt-2 border border-gray-300 rounded"
        value={breakDown.vehicle_id}
        onChange={(e) => handleVehicleIDChange(e.target.value)}
      >
        <option value="" disabled>
          Select the vehicle
        </option>
        {vehicleDetails.map((item, index) => (
          <option key={index} value={item.vehicle_id}>
            {item.vehicle_id} - {item.vehicle_no_plate_no || "No Plate"}
          </option>
        ))}
      </select>

              </div>

              {/* Breakdown ID */}
              <div>
                <label className="block text-gray-700">Breakdown ID*</label>
                <input
                  type="text"
                  name="breakdown_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

                    {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID*</label>

                <select
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.driver_id}
                  onChange={(e) => handleDriverIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the driver
                  </option>
                  {driverDetails.map((items, index) => (
                    <option key={index} value={items.driver_id}>
                      {items.driver_id} - {items.employee_fullname}
                    </option>
                  ))}
                </select>
              </div>

              {/* Breakdown Date */}
              <div>
                <label className="block text-gray-700">Breakdown Date*</label>
                <input
                  type="date"
                  name="breakdown_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_date}
                  onChange={handleInputChange}
                />
              </div>



              {/* breakdown time */}
                <div>
                <label className="block text-gray-700">Breakdown Date*</label>
                <input
                  type="time"
                  name="breakdown_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_time}
                  onChange={handleInputChange}
                />
              </div>

              {/* Breakdown Time */}
              {/* <div>
                <label className="block text-gray-700">Breakdown Time*</label>
                <input
                  type="time"
                  name="breakdown_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_time}
                  
                />
              </div> */}

              <div>
                <label className="block text-gray-700 mb-2">
                  Breakdown Location
                </label>
                <GooglePlacesAutocomplete
                  apiKey={backendUrl2} // Replace with your Google Maps API key
                  selectProps={{
                    onChange: (place) =>
                      LocationChange("breakdown_location", place),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                />
              </div>

     
              {/* Remark */}
              <div>
                <label className="block text-gray-700">Remark*</label>
                <input
                  type="text"
                  name="remark"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.remark}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* <h1 className="text-[30px] font-bold mb-8 mt-10">
            Vehicle Registration Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button> */}

          {/* {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )} */}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>



        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default BreakdownRecordsPopup;
