import React, { useEffect, useState } from 'react';
import { IoCheckmarkCircle } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";

const Generated_Payroll_Approve = () => {
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [actionType, setActionType] = useState(null); // To track whether the action is approval or rejection
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [rowsPerPage] = useState(5); // Rows per page
  const [pagesToShow, setPagesToShow] = useState(5); // Show 5 pages at a time

  useEffect(() => {
    // Fetch data from the API when the component is mounted
    const fetchData = async () => {
      try {
        const response = await fetch('https://back-ayurveda.erp-quortech.com/v1/hris/payroll/pending-payroll');
        const result = await response.json();

        if (result.success) {
          setTableData(result.data); // Set the data into state
        } else {
          console.error('Failed to fetch payroll data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetch function
  }, []);

  // Calculate the current rows to display based on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

  // Calculate how many pages there are
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  // Function to convert JSON data to CSV format
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map(item =>
      headers.map(header => `"${item[header]}"`).join(",")
    );
    return [headers.join(","), ...rows].join("\n");
  };

  // Function to download CSV
  const downloadCSV = (data, filename = "payroll_data.csv") => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  // Function to fetch payroll data based on the ID and trigger CSV download
  const handleExportClick = async (id) => {
    try {
      const response = await fetch(`https://back-ayurveda.erp-quortech.com/v1/hris/payroll/json-payroll?id=${id}`);
      const result = await response.json();

      if (result.success) {
        // Download the payroll data as CSV
        downloadCSV(result.data, `payroll_data_${id}.csv`);
      } else {
        console.error('Failed to fetch detailed payroll data for export');
      }
    } catch (error) {
      console.error('Error fetching payroll data for export:', error);
    }
  };

  // Function to handle the status approval
  const handleApprove = async (id) => {
    try {
      const response = await fetch('https://back-ayurveda.erp-quortech.com/v1/hris/payroll/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          status: 'approved',
        }),
      });

      const result = await response.json();

      if (result.success) {
        // Update the status in the state to reflect the change
        setTableData(prevData =>
          prevData.map(item =>
            item.id === id ? { ...item, status: 'approved' } : item
          )
        );
        setIsModalOpen(false); // Close the modal after approval
      } else {
        console.error('Failed to approve payroll');
      }
    } catch (error) {
      console.error('Error approving payroll:', error);
    }
  };

  // Function to handle the status rejection
  const handleReject = async (id) => {
    try {
      const response = await fetch('https://back-ayurveda.erp-quortech.com/v1/hris/payroll/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          status: 'rejected',
        }),
      });

      const result = await response.json();

      if (result.success) {
        // Update the status in the state to reflect the change
        setTableData(prevData =>
          prevData.map(item =>
            item.id === id ? { ...item, status: 'rejected' } : item
          )
        );
        setIsModalOpen(false); // Close the modal after rejection
      } else {
        console.error('Failed to reject payroll');
      }
    } catch (error) {
      console.error('Error rejecting payroll:', error);
    }
  };

  // Function to open the modal
  const openModal = (id, action) => {
    setSelectedId(id); // Set the selected payroll id
    setActionType(action); // Set the action type (approve or reject)
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedId(null); // Reset selected id
  };

  // Handle next page (Show More)
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  // Handle previous page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Handle Show More button
  const handleShowMore = () => {
    setPagesToShow(pagesToShow + 5);
  };

  return (
    <div className='bg-background overflow-y-auto h-screen'>
      <div className='mx-6 mt-5 font-bold text-[20px]'>
        Generated Payroll Approve Section
      </div>

      <div className="ml-6 mt-4">
        <table className="w-full table-auto border-collapse">
          <thead className='bg-white'>
            <tr>
              <th className="text-primary px-4 py-2 text-left">Generated Date</th>
              <th className="text-primary px-4 py-2 text-left">Generated Time</th>
              <th className="text-primary px-4 py-2 text-left">Generated By</th>
              <th className="text-primary px-4 py-2 text-left">Status</th>
              <th className="text-primary px-4 py-2 text-left">Action</th>
              <th className="text-primary px-4 py-2 text-left">Export</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((item) => (
              <tr key={item.id}>
                <td className="px-4 py-2">{new Date(item.generated_date).toLocaleDateString()}</td>
                <td className="px-4 py-2">{item.generated_time}</td>
                <td className="px-4 py-2">{item.generated_by}</td>
                <td className="px-4 py-2">{item.status}</td>
                <td className="px-4 py-2">
                  <div className='flex gap-5 items-center'>
                    {/* Show "Already Marked" when status is not 'pending' */}
                    {item.status !== 'pending' ? (
                      <span className="text-gray-500">Already Marked</span>
                    ) : (
                      <>
                        <div
                          className='text-green-400 p-2 rounded-full cursor-pointer'
                          onClick={() => openModal(item.id, 'approve')} // Open the modal for approval
                        >
                          <IoCheckmarkCircle size={25} />
                        </div>
                        <div
                          className='text-red-400 p-2 rounded-full cursor-pointer'
                          onClick={() => openModal(item.id, 'reject')} // Open the modal for rejection
                        >
                          <RxCrossCircled size={25} />
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td className="px-4 py-2">
                  <button
                    className='bg-red-400 text-white p-2 w-[150px] font-bold rounded-lg justify-center flex'
                    onClick={() => handleExportClick(item.id)} // Trigger export
                  >
                    Export as csv
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex justify-center gap-8 items-center mt-4">
          {/* Previous Button */}
          <button
            onClick={handlePreviousPage}
            className="bg-gray-400 text-white px-4 py-2 rounded-md"
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {/* Show More Button */}
          {currentPage * rowsPerPage < tableData.length && (
            <button
              onClick={handleShowMore}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Show More
            </button>
          )}

          {/* Next Button */}
          <button
            onClick={handleNextPage}
            className="bg-gray-400 text-white px-4 py-2 rounded-md"
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
            <h3 className="text-xl font-semibold">
              {actionType === 'approve' ? 'Confirm Approval' : 'Confirm Rejection'}
            </h3>
            <p className="mt-4">
              Are you sure you want to {actionType === 'approve' ? 'approve' : 'reject'} this payroll?
            </p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded"
                onClick={closeModal} // Close the modal
              >
                Cancel
              </button>
              <button
                className={`${actionType === 'approve' ? 'bg-green-500' : 'bg-red-500'
                  } text-white px-4 py-2 rounded`}
                onClick={() => {
                  if (actionType === 'approve') {
                    handleApprove(selectedId); // Call approve function
                  } else {
                    handleReject(selectedId); // Call reject function
                  }
                }}
              >
                {actionType === 'approve' ? 'Confirm' : 'Reject'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Generated_Payroll_Approve;
