/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../Assets/icons/csv.png";
import Cookies from "js-cookie";
import usePermissions from "../../components/permissions/permission";
import Papa from "papaparse";

const BranchItemReturnReleasedRequestList = () => {
  const [items, setItems] = useState([]);
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [status, setStatus] = useState("ACCEPTED");
  const branchId = Cookies.get("branch");

  useEffect(() => {
    fetchItemData();
  }, []);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/BracnReturn/requested-return?status=${status}&branch_id=${branchId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data.data);
    } catch (error) {
      console.error("Error fetching pending return request data:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  const HandleOnClick = (itemReq) => {
    navigate("/inventory/branch-item-return-released-req-view-branch-side", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredItems = items.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.inventory_request_by
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.inventory_request_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    const monthMatch =
      !searchMonth ||
      new Date(item.permanant_appoinment_date).getMonth() + 1 ===
        parseInt(searchMonth);

    const yearMatch =
      !searchYear ||
      new Date(item.permanant_appoinment_date).getFullYear().toString() ===
        searchYear;

    return searchMatch && monthMatch && yearMatch;
  });

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleExportCSV = () => {
    const csvData = currentItems.map((itemReq) => ({
      "Return Requested ID": itemReq.return_id,
      "Return Requested Date": new Date(itemReq.return_at)
        .toISOString()
        .split("T")[0],
      "Branch ID": itemReq.branch_id,
      Status: itemReq.status,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "BranchItemReturnReleasedRequestList.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Released Branch Item Return Requests Details
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Requested ID, Branch"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="monthFilter"
              name="monthFilter"
              value={searchMonth}
              onChange={handleMonthChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option value="">Filter By Requested Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="relative font-sans group hidden sm:block">
            <select
              id="yearFilter"
              name="yearFilter"
              value={searchYear}
              onChange={handleYearChange}
              className="w-[200px] font-bold ml-6 mt-4 sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
            >
              <option value="">Filter By Requested Year</option>
              {Array.from(
                { length: 20 },
                (v, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px]">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Return Requested ID</th>
              <th className="text-primary px-6 py-4">Return Requested Date</th>
              <th className="text-primary px-6 py-4">Branch ID</th>
              <th className="text-primary px-6 py-4">Status</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems.map((itemReq) => (
              <tr key={itemReq.id}>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.return_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {new Date(itemReq.return_at).toISOString().split("T")[0]}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.branch_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.status}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  <button
                    className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                    onClick={() => HandleOnClick(itemReq)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredItems.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-500" : "bg-gray-300"
              } text-white px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredItems.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      <div className="flex justify-end">
        <button
          className="bg-white font-semibold font-sans flex mr-8 mt-6 text-[16px] w-40 h-14 border border-white rounded-md"
          onClick={handleExportCSV}
        >
          <img
            src={CSV}
            className="w-[25px] h-[25px] mr-4 mt-2 ml-3"
            alt="Export CSV"
          />
          <span className="mt-3">Export CSV</span>
        </button>
      </div>
    </div>
  );
};

export default BranchItemReturnReleasedRequestList;
