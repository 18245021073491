/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Pos_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change

  const permissionHierarchy = useMemo(
    () => ({
      1: { children: [100, 101, 102, 103, 104] },
      100: { children: [1070] },
      102: { children: [1090, 1091, 1092] },
      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[1].children.includes(perm)
      ) &&
      !selectedPermissions.includes(1)
    ) {
      selectedPermissions.push(1);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline"
            Value="1"
          >
            POS
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p
                  className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black"
                  value="1"
                >
                  POS
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="100"
                      checked={!!checkedValues[100]}
                      onChange={() => handleCheckboxChange(100)}
                    />
                    POS
                  </label>
                  <div className="ml-[9%]">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="1070"
                            checked={!!checkedValues[1070]}
                            onChange={() => handleCheckboxChange(1070, 100)}
                          />
                          Add
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p
                  className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black"
                  value="3"
                >
                  POS Bill
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="101"
                    checked={!!checkedValues[101]}
                    onChange={() => handleCheckboxChange(101)}
                  />
                  POS Bill
                </label>
              </div>

              <div>
                <p
                  className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black"
                  value="4"
                >
                  POS Dashboard
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="102"
                    checked={!!checkedValues[102]}
                    onChange={() => handleCheckboxChange(102)}
                  />
                  POS Dashboard
                </label>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1090"
                          checked={!!checkedValues[1090]}
                          onChange={() => handleCheckboxChange(1090)}
                        />
                        Sales
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1091"
                          checked={!!checkedValues[1091]}
                          onChange={() => handleCheckboxChange(1091)}
                        />
                        Total Sales
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1092"
                          checked={!!checkedValues[1092]}
                          onChange={() => handleCheckboxChange(1092)}
                        />
                        Total Sold Items
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p
                  className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black"
                  value="5"
                >
                  POS Items
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="103"
                    checked={!!checkedValues[103]}
                    onChange={() => handleCheckboxChange(103)}
                  />
                  POS Items
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pos_Permission;
