import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import AddSampleTesting from "./add_sample_testing";
import EditSampleTesting from "./edit_sample_testing";
import DetailTestingStagesOpen from "./details_testing_stages";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";

const Sample_testing = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [tableData, setTableData] = useState([]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [detailTestingStagesOpen, setDetailTestingStagesOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  // Define state for start date and end date
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [row, setRow] = useState();
  const [editOpen, setEditOpen] = useState();
  const [tableRest, setTableReset] = useState(false);

  const { hasPermission } = usePermissions();

  // table rest
  if (tableRest == true) {
    // getTableData();
    setTableReset(false);
  }

  const rowsPerPage = 5; // Number of rows per page

  // Calculate the number of pages
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  // Get the current rows for the page
  const currentRows = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Helper function to format date to 'YYYY-MM-DD'
  const formatDateForInput = (date) => {
    if (!date) return ""; // Return an empty string if date is invalid
    const formattedDate = new Date(date);
    return isNaN(formattedDate) ? "" : formattedDate.toISOString().slice(0, 10); // Format to 'YYYY-MM-DD'
  };

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  // Function to handle CSV export
  const handleExportCSV = () => {
    console.log("Export to CSV");
    // Implement CSV export logic here
  };

  // Function to handle PDF export
  const handleExportPDF = () => {
    console.log("Export to PDF");
    // Implement PDF export logic here
  };

  // Function to handle start date change
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  // Function to handle end date change
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  // Function to handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const editSampleAllocation = (row) => {
    setRow(row);
    setEditOpen(true);
  };

  const closeEditModal = () => {
    setEditOpen(!editOpen);
  };

  const closeDetailsTestingStageModal = () => {
    setDetailTestingStagesOpen(!detailTestingStagesOpen);
  };

  const detailsTestingStages = (row) => {
    const temp = row.quality_test_stages;
    setRow(temp);
    setDetailTestingStagesOpen(true);
  };

  const getTableData = async () => {
    console.log(searchTerm, startDate, endDate);

    try {
      const params = {};

      if (searchTerm) {
        params.sample_test_id = searchTerm;
      }
      if (startDate) {
        params.start_date = startDate;
      }
      if (endDate) {
        params.end_date = endDate;
      }

      const response = await axios.get(
        `${backendUrl}/qualitySampleTest/getQualitySampleTesting`,
        {
          params,
        }
      );

      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  const deleteSampleTestingAllocation = (rowId) => {
    setDeleteRowID(rowId);
    setDeleteConfirmationOpen(true);
  };


  const handleDeleteForm = async () => {
    try {
      const response = await axios.delete(
        `${backendUrl}/qualitySampleTest/deleteSampleTesting/${deleteRowID}`,
      );

      setNotification({
        message: "Sample details deleted successfully!",
        type: "success",
      });
      getTableData();
    } catch (error) {
      console.error("Error deleting item:", error);
      setNotification({
        message: "Error deleting sample details. Please try again.",
        type: "error",
      });
    }

    setDeleteConfirmationOpen(false);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteRowID("");
    setDeleteConfirmationOpen(false);
  };


// Export table to PDF
  const PdfExport = () => {
    const doc = new jsPDF();
    doc.text("Sample Testing Report", 14, 10);

    const columns = [
      "Sample Test Id",
      "Sample ID",
      "Sample Type",
      "Sample Name",
      "Received Date",
      "Result",
      "Remark",
    ];

    const rows = tableData.map((row) => [
      row.sample_test_id,
      row.sample_id,
      row.sample_type,
      row.sample_name,
      new Date(row.received_date).toLocaleDateString("en-LK"),
      row.result,
      row.remark,      
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: rows,
    });

    doc.save("sample_test_report.pdf");
  };

  // Export to csv
  const ExcelExport = () => {
      const csvData = Papa.unparse(
        tableData.map((row) => ({
          "sample test id": row.sample_test_id,
          "sample id": row.sample_id,
          "sample type": row.sample_type,
          "sample name": row.sample_name,
          "received date": new Date(row.received_date).toLocaleDateString("en-LK"),
          "result": row.result,
          "remark": row.remark,      
        }))
      );
  
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "accounts_payable.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };


  useEffect(() => {
    getTableData();
  }, [searchTerm, startDate, endDate]);

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">Sample Testing</p>
          </div>
        </div>

        {/* Controls with Add Button */}
        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/* Search Box with Manual Width */}
              <div className="w-full mt-6 md:w-[300px]">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Start Date Selector with Manual Width */}
              <div className="w-full md:w-[200px]">
                <label className="block mb-1 text-gray-700">Start Date</label>
                <input
                  type="date"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>

              {/* End Date Selector with Manual Width */}
              <div className="w-full md:w-[200px]">
                <label className="block mb-1 text-gray-700">End Date</label>
                <input
                  type="date"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>

            {/* Right-Side Buttons */}
            <div className="relative flex items-center gap-4 mt-4 md:mt-0">
              {/* Add Button */}

              {hasPermission(8005) && (
                <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                  onClick={togglePopup}
                >
                  <IoAdd className="text-xl" />
                  Add Sample Testing
                </button>
              )}

              {hasPermission(8008) && (
                <button className="flex items-center gap-2 px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                onClick={PdfExport}
                >
                  <IoAdd className="text-xl" />
                  PDF to Export
                </button>
              )}

              {hasPermission(8009) && (
                <button className="flex items-center gap-2 px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                  onClick = {ExcelExport}
                >
                  <IoAdd className="text-xl" />
                  CSV to Export
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 overflow-x-auto mt-14">
        {/* TailwindCSS Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample Testing ID
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample ID
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample Type
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Tender/ Bulk Received Date
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Details of Testing Stages
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Result
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Remark
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentRows.map((row) => (
              <tr key={row.sample_test_id}>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_test_id}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_id}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_type}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {new Date(row.received_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long", // Use "short" for abbreviated month names
                    day: "2-digit",
                  })}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  <button
                    className="p-2 text-blue-500 rounded hover:bg-blue-100"
                    onClick={() => detailsTestingStages(row)}
                  >
                    Click Here
                  </button>
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.result}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.remark}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {hasPermission(8006) && (
                    <button
                      className="p-2 text-blue-500 rounded hover:bg-blue-100"
                      onClick={() => editSampleAllocation(row)}
                    >
                      <FaEdit />
                    </button>
                  )}

                  {hasPermission(8007) && (
                  <button
                    className="p-2 ml-2 text-red-500 rounded hover:bg-red-100"
                    onClick={() => deleteSampleTestingAllocation(row.sample_test_id)}
                  >
                    <FaTrash />
                  </button>
                )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={`px-3 py-1 mx-1 text-sm rounded ${
              currentPage === i + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
      {/* Add Sample Allocation Process Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <AddSampleTesting
              togglePopup={togglePopup}
              resetTable={setTableReset}
            />
          </div>
        </div>
      )}
      {/* Edit Sample Allocation Process Popup */}
      {editOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <EditSampleTesting togglePopup={closeEditModal} row={row} />
          </div>
        </div>
      )}
      {deleteConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
            <p>
              Are you sure you want to delete? This action cannot be
              undone.
            </p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-xl"
                onClick={handleDeleteForm}
              >
                Yes, Delete
              </button>
              <button
                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                onClick={handleCloseDeleteConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Details Of Testing Stages */}
      {detailTestingStagesOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <DetailTestingStagesOpen
              togglePopup={closeDetailsTestingStageModal}
              row={row}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sample_testing;
