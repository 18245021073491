/** @format */

import React, { useEffect, useRef, useState } from "react";
import { IoAdd } from "react-icons/io5";
import Navbar from "../../../components/navbar/navbar";
import { FcApprove } from "react-icons/fc";
import { FcDisapprove } from "react-icons/fc";
import axios from "axios";
import Notification from "../../../components/notification/notification";
import Papa from "papaparse";


const Vehicle_Documents_Uploading = () => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [seletedValue, setSeletedValue] = useState({
    search: "",
    start_date: "",
    end_date: "",
  });
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [comment, setComment] = useState(""); // To capture approve/reject comment
  const [actionType, setActionType] = useState(""); // To track the action type
  const [vehicleId, setVehicleId] = useState("");

  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const handleRowSelect = (id) => {
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setVehicleId(id);
      setSelectedRow(id);

      setDropdownVisible(true);
    }
  };

  const handleApprove = (vehicleItem) => {
    setActionType("APPROVED");
    setIsPopupOpen(true); // Open the popup when "Approve" is clicked
  };

  const handleReject = (vehicleItem) => {
    setActionType("REJECTED");
    setIsPopupOpen(true); // Open the popup when "Reject" is clicked
  };

  const getTableData = async () => {
    try {
      const params = {
        search: seletedValue.search,
        start_date: seletedValue.start_date,
        end_date: seletedValue.end_date,
        page_size: rowsPerPage,
        page: pagination.current_page,
      };

      const response = await axios.get(
        `${backendUrl}/vehicle/getDataforApprove2`,
        {
          params,
        }
      );

      if (response.data) {
        console.log('---VAP2---')
        console.log(response.data)
        console.log('---VAP2---')
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1,
    }));
  };

   // Excel export function
   const ExcelExport = () => {
    const csvData = tableData.map((row) => ({
      "Vehicle ID": row.vehicle_id,
      "Approved Status 2": row.approved_status_1,
      "Approved Status 2 Description": row.approved_status_1_desc,
    }));

    const csv = Papa.unparse(csvData);

    // Create a blob and download it as a CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "Approval-process-02.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  const downloadPDF = async (row) => {
    console.log(row);

    const temp = row.doc; 
    try {

      setNotification({
        message: "Downloading document...",
        type: "success",
    });
      const response = await axios.get(`${backendUrl}/vehicle/downloadFiles`, {
        params: {
          registration_upload_path: temp, // Pass registration_upload_path as a query param
        },
        responseType: "blob", // Ensure we expect a blob response
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "VehicleDocument.pdf"; // Add .pdf extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  const handleConfirmAction = async (actionType) => {
    try {
      const response = await fetch(
        `${backendUrl}/vehicle/EditDataforApprove2?vehicle_id=${vehicleId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            approved_status_2: actionType,
            approved_status_2_desc: comment,
          }),
        }
      );

      getTableData();
      setComment("");

      setNotification({
        message: "Successfuly Updated !",
        type: "success",
      });
    } catch (error) {
      console.error("Error updating approval status:", error);
    }

    setIsPopupOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const actionsRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getTableData();
  }, [
    rowsPerPage,
    pagination.current_page,
    seletedValue,
    seletedValue.search_term,
  ]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="">
        <div className="mx-10 mt-5 -ml-1">
          <Navbar />
          <div className="flex items-center justify-between mt-6 ">
            <div>
              <p className="text-[30px] font-semibold">Approval-process-02</p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Document uploading vehicle
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center md:gap-4 mt-6">
            <div className="w-full md:w-[350px] mt-7">
              <input
                type="text"
                placeholder="search vehicle Id"
                name="search"
                value={seletedValue.start}
                onChange={handleChange}
                className="w-full p-2 border border-black rounded focus:ring-2"
              />
            </div>

            <div className="w-full md:w-[350px]">
              <label className="block text-gray-700">Start date</label>
              <input
                type="date"
                placeholder="search start date"
                name="start_date"
                value={seletedValue.start_date}
                onChange={handleChange}
                className="w-full p-2 border border-black rounded focus:ring-2"
              />
            </div>

            <div className="w-full md:w-[350px]">
              <label className="block text-gray-700">End date</label>

              <input
                type="date"
                placeholder="search end date"
                name="end_date"
                value={seletedValue.end_date}
                onChange={handleChange}
                className="w-full p-2 border border-black rounded focus:ring-2"
              />
            </div>

            {/* Notification Alert */}
            <Notification
              message={notification.message}
              type={notification.type}
              onClose={handleCloseNotification}
            />

            <div
              className="relative flex items-center gap-4 ml-auto"
              id="actions"
              ref={actionsRef}
            >
              <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-green-400 to-green-600 rounded hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                onClick={ExcelExport}
              >
                <IoAdd className="text-xl" />
                Excel Export
              </button>
              {selectedRow ? (
                <div className="relative">
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Actions
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                      <button
                        onClick={() => handleApprove(selectedRow)}
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      >
                        <FcApprove className="mr-2" /> Approve
                      </button>
                      <button
                        onClick={() => handleReject(selectedRow)}
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      >
                        <FcDisapprove className="mr-2" /> Reject
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="my-4 overflow-x-auto mt-10 z-auto">
          {/* Scrollable Table Container with Increased Height */}
          <div className="max-h-[600px] overflow-y-auto">
            {/* TailwindCSS Table */}
            <table className="min-w-full bg-white border border-black ">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                    Vehicle ID
                  </th>
                  <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                    Approved Status 2
                  </th>
                  <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                    Approved Status 2 Description
                  </th>
                  <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
                    Document
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr
                    key={index}
                    className={`${
                      selectedRow === row.vehicle_id
                        ? "bg-blue-600 text-white"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleRowSelect(row.vehicle_id)}
                  >
                    <td className="px-6 py-4 border-b border-black">
                      {row.vehicle_id}
                    </td>
                    <td
                      className={`px-6 py-4 border-b border-black ${
                        row.approved_status_2 === "NOT APPROVED"
                          ? "text-blue-500"
                          : row.approved_status_2 === "REJECTED"
                          ? "text-red-500"
                          : row.approved_status_2 === "APPROVED"
                          ? "text-green-500"
                          : "text-gray-500" // Default color for unknown status
                      }`}
                    >
                      {row.approved_status_2}
                    </td>
                    <td className="px-6 py-4 border-b border-black">
                      {row.approved_status_2_desc}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                      <button
                        onClick={() => downloadPDF(row)}
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                      >
                        Download PDF
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4 gap-2">
          {[20, 40, 60].map((size) => (
            <button
              key={size}
              onClick={() => handleRowsPerPageChange(size)}
              className={`px-4 py-2 ${
                rowsPerPage === size
                  ? "bg-green-600 text-white" // Active button with green background and white text
                  : "bg-green-300 text-black" // Inactive button with lighter green background and black text
              } rounded hover:bg-green-500`} // Hover effect for both states
            >
              {size}
            </button>
          ))}
          <button
            onClick={() =>
              setPagination((prevPagination) => ({
                ...prevPagination,
                currentPage: Math.min(
                  prevPagination.currentPage + 1,
                  prevPagination.totalPages
                ),
              }))
            }
            disabled={pagination.currentPage >= pagination.totalPages}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
          >
            Load More
          </button>
        </div>
      </div>

      {/* Popup for confirming Approve/Reject with a comment */}
      {isPopupOpen && (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-md p-6 bg-white rounded-md">
            <h3 className="text-lg font-semibold">
              {actionType === "APPROVED" ? "approve" : "reject"} Confirmation
              reject
            </h3>
            <textarea
              placeholder="Enter your comment"
              className="w-full p-2 mt-4 border border-black rounded"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <div className="flex justify-end gap-4 mt-4">
              <button
                className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-800"
                onClick={() => setIsPopupOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-green-600 rounded hover:bg-green-800"
                onClick={() => handleConfirmAction(actionType)} // Pass the selected vehicle_id
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Vehicle_Documents_Uploading;
