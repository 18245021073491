/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import systemsList from "./side_bar_data"; // Ensure the path is correct
import usePermissions from "../permissions/permission";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import logo from "../../Assets/icons/ERP1.png"; // Update the path to your logo
import {
  TbLayoutSidebarLeftExpand,
  TbLayoutSidebarRightExpand,
} from "react-icons/tb";
import "./scrollbar.css"; // Import the custom CSS file
import { MdLogout } from "react-icons/md";

const SideMenu = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [expandedMenus, setExpandedMenus] = useState({});
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const { hasPermission, hasAnyFeaturePermission, permissions } =
    usePermissions(); // Include permissions in the destructuring assignment
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  useEffect(() => {
    // Automatically navigate to the first accessible feature
    const navigateToFirstAccessibleFeature = () => {
      for (const mainFeature of systemsList) {
        if (
          hasPermission(mainFeature.requiredPermissionsformainfeatures) ||
          hasAnyFeaturePermission(
            mainFeature.features,
            "requiredPermissionsforsubfeatures"
          )
        ) {
          for (const feature of mainFeature.features) {
            if (hasPermission(feature.requiredPermissionsforsubfeatures)) {
              navigate(feature.url);
              return;
            }
          }
        }
      }
    };

    // Ensure this navigation only happens on the initial load
    if (location.pathname === "/") {
      navigateToFirstAccessibleFeature();
    }
  }, [hasPermission, hasAnyFeaturePermission, navigate, location.pathname]);

  useEffect(() => {
    // Listen for the back button event
    window.onpopstate = () => {
      // If user is at the root page and logged out, do nothing
      if (location.pathname === "/") {
        navigate("/");
      }
    };

    // Function to handle clicks outside the sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
        setExpandedMenus({});
      }
    };

    // Add event listener for clicks outside the sidebar
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [location.pathname, navigate]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setExpandedMenus({});
    }
  };

  const toggleSubMenu = (id, level = "main") => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setExpandedMenus((prev) => {
        const newState = { ...prev };
        if (level === "main") {
          newState[id] = !prev[id];
        } else {
          newState[id] = !prev[id];
        }
        return newState;
      });
    }
  };

  const isSelectedPath = (path) => {
    return (
      location.pathname === path || location.pathname.startsWith(`${path}/`)
    );
  };

  const renderFeatures = (features, isOpen, parentPath = "") => {
    return (
      <ul className="ml-6">
        {features.map((feature) => {
          if (
            hasPermission(feature.requiredPermissionsforsubfeatures) ||
            hasAnyFeaturePermission(
              feature.subFeatures,
              "requiredPermissionsforsubfeatures"
            )
          ) {
            const currentPath = `${parentPath}${feature.url}`;
            const isSelected = isSelectedPath(currentPath);
            const hasSelectedSubFeature = feature.subFeatures?.some(
              (subFeature) =>
                isSelectedPath(`${currentPath}${subFeature.url}`) ||
                subFeature.subFeatures?.some((subSubFeature) =>
                  isSelectedPath(
                    `${currentPath}${subFeature.url}${subSubFeature.url}`
                  )
                )
            );

            return (
              <li
                key={feature._id}
                className={`p-2 hover:bg-gray-200 rounded-md ${
                  isSelected || hasSelectedSubFeature
                    ? "font-bold text-black"
                    : ""
                }`}
              >
                <Link
                  to={feature.url || "#"}
                  className={`flex items-center cursor-pointer ${
                    isSelected ? "font-bold text-black" : ""
                  }`}
                  onClick={() => {
                    if (!isOpen) {
                      toggleSidebar();
                    } else {
                      toggleSubMenu(feature._id, "sub");
                    }
                  }}
                >
                  <div className="flex items-center">
                    {isOpen && (
                      <>
                        {!feature.subFeatures && isSelected && (
                          <span className="inline-block w-2 h-2 mr-2 bg-green-500 rounded-full"></span>
                        )}
                        {feature.name}
                      </>
                    )}
                  </div>
                  {isOpen && feature.subFeatures && (
                    <span className="ml-auto">
                      {expandedMenus[feature._id] ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                  )}
                </Link>
                {expandedMenus[feature._id] &&
                  feature.subFeatures &&
                  renderFeatures(feature.subFeatures, isOpen, currentPath)}
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const renderMainFeatures = (mainFeatures, isOpen) => {
    return (
      <ul>
        {mainFeatures.map((mainFeature) => {
          const isMainSelected = mainFeature.features?.some(
            (feature) =>
              isSelectedPath(feature.url) ||
              feature.subFeatures?.some(
                (subFeature) =>
                  isSelectedPath(`${feature.url}${subFeature.url}`) ||
                  subFeature.subFeatures?.some((subSubFeature) =>
                    isSelectedPath(
                      `${feature.url}${subFeature.url}${subSubFeature.url}`
                    )
                  )
              )
          );

          if (
            hasPermission(mainFeature.requiredPermissionsformainfeatures) ||
            hasAnyFeaturePermission(
              mainFeature.features,
              "requiredPermissionsforsubfeatures"
            )
          ) {
            return (
              <li key={mainFeature._id} className="mt-1">
                <Link
                  to={mainFeature.url || "#"}
                  className={`flex items-center cursor-pointer p-2 rounded-md ${
                    isMainSelected ? "font-bold text-black" : ""
                  }`}
                  onClick={() => {
                    if (!isOpen) {
                      toggleSidebar();
                    } else {
                      toggleSubMenu(mainFeature._id, "main");
                    }
                  }}
                >
                  <span
                    className={`${
                      isMainSelected ? "text-black" : "text-gray-700"
                    }`}
                  >
                    {React.cloneElement(mainFeature.icon, {
                      size: 24,
                      color: isMainSelected ? "black" : "gray",
                    })}
                  </span>
                  {isOpen && <span className="ml-2">{mainFeature.name}</span>}
                  {isOpen && mainFeature.features && (
                    <span className="ml-auto">
                      {expandedMenus[mainFeature._id] ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                  )}
                </Link>
                {expandedMenus[mainFeature._id] &&
                  mainFeature.features &&
                  renderFeatures(mainFeature.features, isOpen)}
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.clear();

    // Clear all cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Redirect to login page
    navigate("/", { replace: true });

    // Push a new state to the history to prevent back navigation
    window.history.pushState(null, null, window.location.href);
  };

  return (
    <div
      ref={sidebarRef}
      className={`flex flex-col h-screen bg-white p-5 pt-8 relative duration-300 ${
        isOpen ? "w-64" : "w-[90px]"
      } scrollbar-custom`}
    >
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-9 w-10  mb-5 h-10 mr-2 mt-12 rounded-full flex items-center justify-center"
      >
        {isOpen ? (
          <TbLayoutSidebarRightExpand size={24} />
        ) : (
          <TbLayoutSidebarLeftExpand size={24} />
        )}
      </button>
      <div className="flex flex-col items-center mb-6">
        <img src={logo} alt="Logo" className="w-20 h-10 mb-4" />
        {isOpen && (
          <div className="text-center mr-2">
            <p className="text-md font-semibold text-black">
              Sri Lanka Ayurvedic Drugs Corporation
            </p>
          </div>
        )}
      </div>
      <div className="scrollbar flex-1 overflow-y-auto mt-5 " id="style-2">
        <div className="force-overflow text-[15px] ">
          {permissions.length > 0 && renderMainFeatures(systemsList, isOpen)}
        </div>
      </div>

      <div className="mt-2">
        <button
          type="button"
          className={`bg-white text-center ${
            isOpen ? "w-48" : "w-[60px]"
          } rounded-2xl h-14 relative font-sans text-black text-xl font-semibold group`}
          onClick={() => setShowLogoutConfirmation(true)}
        >
          <div
            className={`bg-green-400 rounded-xl h-12 flex items-center justify-center absolute left-1 top-[4px] z-10 ${
              isOpen ? "w-1/4 group-hover:w-[184px] duration-500" : "w-[60px]"
            }`}
          >
            <MdLogout />
          </div>
          {isOpen && <p className="translate-x-2">Logout</p>}
        </button>
      </div>

      {showLogoutConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-[20px]">
            <p className="mb-4 font-semibold text-[18px]">
              Are you sure you want to log out?
            </p>
            <div className="flex justify-center">
              <button
                className="bg-gray-200 px-4 py-2 rounded-md mr-2 font-semibold"
                onClick={() => setShowLogoutConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 font-semibold rounded-md"
                onClick={handleLogout}
              >
                Yes, Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
