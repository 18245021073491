import { React, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";

const Bid_Evaluation_Summary_Report = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tenderId = location.state;
  console.log("Location", location.state);
  const [nameOfProcurementEntity, setnameOfProcurementEntity] = useState("");
  const [nameOfProcurement, setNameOfProcurement] = useState("");
  const [sourceFinacialResources, setSourceFinacialResources] = useState("");
  const [prebidEstimatedAmount, setPrebidEstimatedAmount] = useState("");
  const [methodOfProcurement, setMethodOfProcurement] = useState("");
  const [numbersOfbidersDocument, setNumbersOfbidersDocument] = useState("");
  const [numberOfBidsReceived, setNumberOfBidsReceived] = useState("");
  const [numberOfbidsDeterminded, setNumberOfbidsDeterminded] = useState("");
  const [anyCommonReasons, setAnyCommonReasons] = useState("");
  const [bidderRecommendation, setBidderRecommendation] = useState("");
  const [ifTheAnswertoAbove, setIftheAnsertoAbove] = useState("");
  const [recommendedPrice, setRecommendedPrice] = useState("");
  const [recommendedBidderPrice, setRecommendedBidderPrice] = useState("");
  const [deferentBetweenReason, setDeferentBetweenReason] = useState("");
  const [date, setdate] = useState(new Date());
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleRecommendationChange = (event) => {
    setBidderRecommendation(event.target.value);
  };

  const handleNumberInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setter(value === "" ? "" : Math.max(0, parseInt(value))); // Prevent negative numbers
    }
  };

  const handleSubmit = async () => {
    try {
      // Create the JSON body
      const body = {
        tender_id: tenderId.pr_id,
        proc_name: nameOfProcurement,
        proc_entity: nameOfProcurementEntity,
        fin_source: sourceFinacialResources,
        pre_bid_est_amt: prebidEstimatedAmount,
        proc_method: methodOfProcurement,
        docs_issued: numbersOfbidersDocument,
        bids_received: numberOfBidsReceived,
        responsive_bids: numberOfbidsDeterminded,
        non_resp_reasons: anyCommonReasons,
        low_bid_award: bidderRecommendation,
        no_award_reason: ifTheAnswertoAbove,
        rec_offer_price: recommendedPrice,
        bid_price_open: recommendedBidderPrice,
        price_diff: deferentBetweenReason,
        user_id: "EMP-00001",
        report_date: date.toLocaleDateString("en-GB"),
      };

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/bid_evaluation_form/add`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Bid Evaluation Summary Report successfully added");
      navigate("/committees/techical");
      // handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Bid Evaluation Summary Report");
    }
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Create: Bid Evaluation Summary Report
      </p>
      <div className=" ml-[80%]">
        <label className="text-[22px] font-bold">PR ID:</label>
        <input
          className="w-40 rounded-lg ml-4 h-8 text-[22px]"
          value={tenderId.pr_id}
          disabled
        ></input>
      </div>

      {/* Form Input Field part */}
      <div class="grid gap-4 grid-cols-2 mt-6 w-[95%] ml-6 ">
        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Name of procurement entity:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%]"
            value={nameOfProcurementEntity}
            onChange={(e) => setnameOfProcurementEntity(e.target.value)}
            required
          ></input>
        </div>
        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Name of Procurement
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%]"
            value={nameOfProcurement}
            onChange={(e) => setNameOfProcurement(e.target.value)}
            required
          ></input>
        </div>
        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Source of financial resources:
          </p>
          <input
            className=" rounded-lg border border-gray-300 h-8 w-[50%]"
            value={sourceFinacialResources}
            onChange={(e) => setSourceFinacialResources(e.target.value)}
            required
          ></input>
        </div>
        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            {" "}
            Pre-bid estimated amount <br /> of procurement contract :
          </p>
          <input
            className=" rounded-lg border border-gray-300 h-8 w-[50%]"
            value={prebidEstimatedAmount}
            onChange={(e) => setPrebidEstimatedAmount(e.target.value)}
            required
          ></input>
        </div>
        <div className="flex justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold">
            Method of procurement <br /> (ICB/NCB/LIB/LNB etc.):{" "}
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%]"
            value={methodOfProcurement}
            onChange={(e) => setMethodOfProcurement(e.target.value)}
            required
          ></input>
        </div>

        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Number of bidder <br /> documents issued:
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%]"
            value={numbersOfbidersDocument}
            onChange={handleNumberInputChange(setNumbersOfbidersDocument)}
            required
          />
        </div>
        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Number of bids received
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%]"
            value={numberOfBidsReceived}
            onChange={handleNumberInputChange(setNumberOfBidsReceived)}
            required
          />
        </div>
        <div className=" flex justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Number of bids determined as
            <br /> substantial responsive bids
          </p>
          <input
            className="rounded-lg border border-gray-300 h-8 w-[50%]"
            value={numberOfbidsDeterminded}
            onChange={handleNumberInputChange(setNumberOfbidsDeterminded)}
            required
          />
        </div>
      </div>

      {/* Form questions  */}
      <div>
        <div className="  ml-6 mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            Any common reason/s if any leading to determination as
            non-responsive bids:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] mt-1"
            value={anyCommonReasons}
            onChange={(e) => setAnyCommonReasons(e.target.value)}
            required
          ></input>
        </div>
        <div class="ml-6 mt-6">
          <p class="text-[22px] text-[#8691AB] font-bold">
            Whether the evaluated lowest bidder is recommended for award of the
            contract:
          </p>
          <div className="ml-6 mt-6">
            <p className="text-[22px] text-[#8691AB] font-bold">
              Whether the evaluated lowest bidder is recommended for award of
              the contract:
            </p>
            <div className="mt-2 text-[22px] text-[#8691AB] font-bold">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio h-6 w-6 text-blue-500"
                  name="recommendation"
                  value="Yes"
                  checked={bidderRecommendation === "Yes"}
                  onChange={handleRecommendationChange}
                  required
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  className="form-radio text-blue-500 h-6 w-6"
                  name="recommendation"
                  value="No"
                  checked={bidderRecommendation === "No"}
                  onChange={handleRecommendationChange}
                  required
                />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        </div>
        <div className="  ml-6 mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            If the answer to above is no give the reason/s:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] mt-1"
            value={ifTheAnswertoAbove}
            onChange={(e) => setIftheAnsertoAbove(e.target.value)}
            required
          ></input>
        </div>
        <div className="  ml-6 mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            What is the recommended offer price:
          </p>
          <input
            type="number"
            className="rounded-lg border border-gray-300 w-[50%] mt-1"
            value={recommendedPrice}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*\.?\d*$/.test(value)) {
                setRecommendedPrice(value);
              }
            }}
            min="0"
            step="0.01"
            required
          />
        </div>
        <div className="  ml-6 mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            What is the recommended bidder’s bid price at bid opening:
          </p>
          <input
            type="number"
            className="rounded-lg border border-gray-300 w-[50%] mt-1"
            value={recommendedBidderPrice}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*\.?\d*$/.test(value)) {
                setRecommendedBidderPrice(value);
              }
            }}
            min="0"
            step="0.01"
            required
          />
        </div>
        <div className="  ml-6 mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold">
            If there is a difference between (12) and (13) above, mention the
            reason/s: 
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[50%] mt-1"
            value={deferentBetweenReason}
            onChange={(e) => setDeferentBetweenReason(e.target.value)}
            required
          ></input>
        </div>
      </div>
      <div className="mb-6 mt-6 ml-6">
        <button
          className="bg-[#5B6D49] text-[20px] font-bold text-white rounded-lg w-[8%] h-8"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Bid_Evaluation_Summary_Report;
