import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import EditAssets from "./edit_assets";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { HiDocumentCheck } from "react-icons/hi2";
import { RiDraftFill } from "react-icons/ri";
import { IoTrashBin } from "react-icons/io5";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import DisposalTable from "./disposal_table";
import { FaRecycle } from "react-icons/fa";
import AssetsRevaluation from "./assets_revaluation";

function Assets(row) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [activeTab, setActiveTab] = useState("AssetsTable");

  const [debouncedFilters, setDebouncedFilters] = useState({
    approvalStatus: "",
    selectedCategory: "",
    selectedDepartment: "",
    fromDate: "",
    toDate: "",
    itemCode: "",
  });

  // State declarations
  const [formData, setFormData] = useState({
    assets_category_id: "",
    item_code: "",
    acquisiton_date: "",
    acquisition_cost: "",
    supplier: "",
    asset_name: "",
    description: "",
    purchase_invoice_id: "",
    warranty_expiry_date: "",
    department: "",
    depreciation_rate: "",
    parent_assets_id: "",
    assetsFile: "",
  });

  const [assetType, setAssetType] = useState("new");

  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [parentNames, setParentNames] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [tableData, setTableData] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState(""); // State for selected approval status
  const [selectedCategory, setSelectedCategory] = useState(""); // For category filter
  const [selectedDepartment, setSelectedDepartment] = useState(""); // For department filter
  const [fromDate, setFromDate] = useState(""); // From Date
  const [toDate, setToDate] = useState(""); // To Date
  const [itemCode, setItemCode] = useState(""); // filter for item code
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedRow, setSelectedRow] = useState(null); // Selected row for actions
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal visibility
  const [actionsVisible, setActionsVisible] = useState(false); // Dropdown visibility
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenEdit2, setIsOpenEdit2] = useState(false);

  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 0,
    total_records: 0,
  });

  const userId = Cookies.get("employee_no");
  const actionsRef = useRef(null);
  const navigate = useNavigate();

  // File download function
  const handleFileDownload = (id) => {
    if (!id) {
      alert("No file available to download.");
      return;
    }

    // Use the provided endpoint and include the assets_id
    window.open(
      `${backendUrl}/assets/assets/downloadAssetsFile/${id}`,
      "_blank"
    );
  };

  // Handle page change
  const handlePageChange = async (page) => {
    if (page > 0 && page <= pagination.total_pages) {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAllAssets`,
          {
            params: {
              page_size: rowsPerPage,
              page, // Use the parameter passed to the function
              approval_status: approvalStatus || undefined,
              category_name: selectedCategory || undefined,
              department: selectedDepartment?.trim() || undefined,
              start_date: fromDate || undefined,
              end_date: toDate || undefined,
              item_code: itemCode || undefined,
            },
          }
        );

        if (response.data) {
          setTableData(response.data.data);
          setPagination((prev) => ({
            ...prev,
            current_page: page, // Update to the clicked page
          }));
        }
      } catch (error) {
        console.error("Error fetching data for page:", page, error);
      }
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  // Other functions and useEffect calls follow

  const validateFormData = () => {
    const requiredFields = [
      "assets_category_id",
      "item_code",
      "acquisition_cost",
      "supplier",
      "asset_name",
      "description",
      "purchase_invoice_id",
      "warranty_expiry_date",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return false;
      }
    }
    return true;
  };

  // Fetch categories and departments
  // Debounce filter changes to avoid excessive API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters({
        approvalStatus,
        selectedCategory,
        selectedDepartment,
        fromDate,
        toDate,
        itemCode,
      });
    }, 300); // Debounce delay for 300ms

    return () => clearTimeout(handler); // Cleanup on dependency change
  }, [
    approvalStatus,
    selectedCategory,
    selectedDepartment,
    fromDate,
    toDate,
    itemCode,
  ]);

  useEffect(() => {
    if (notification.message) {
      const timeout = setTimeout(() => {
        setNotification({ message: "", type: "" });
      }, 1000); // 5 seconds

      // Cleanup timeout if notification changes before 5 seconds
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  const refreshForm = async () => {
    setFormData({
      assets_category_id: "",
      item_code: "",
      acquisiton_date: "",
      acquisition_cost: "",
      supplier: "",
      asset_name: "",
      description: "",
      purchase_invoice_id: "",
      warranty_expiry_date: "",
      department: "",
      depreciation_rate: "",
      parent_assets_id: "",
      assetsFile: "",
    });
  };

  const fetchTableData = async () => {
    setIsLoading(true); // Show loading indicator
    try {
      const response = await axios.get(
        `${backendUrl}/assets/assets/getAllAssets`,
        {
          params: {
            page_size: rowsPerPage,
            page: pagination.current_page,
            approval_status: debouncedFilters.approvalStatus || undefined,
            category_name: debouncedFilters.selectedCategory || undefined,
            department:
              debouncedFilters.selectedDepartment?.trim() || undefined,
            start_date: debouncedFilters.fromDate || undefined,
            end_date: debouncedFilters.toDate || undefined,
            item_code: debouncedFilters.itemCode || undefined,
          },
        }
      );

      if (response.data) {
        setTableData(response.data.data || []);
        setPagination({
          current_page: response.data.pagination.current_page,
          total_pages: response.data.pagination.total_pages,
          total_records: response.data.pagination.total_records,
        });
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  // Fetch Table Data when filters, pagination, or rows per page change
  useEffect(() => {
   
    fetchTableData();
  }, [debouncedFilters, rowsPerPage, backendUrl]);

  // Fetch Categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAssetsCategoryIDs`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [backendUrl]);

  // Fetch Departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getDepartments`
        );
        const processedDepartments = preprocessDepartments(response.data);
        setDepartments(processedDepartments);
      } catch (error) {
        console.error("Error fetching departments:", error);
        setDepartments([]);
      }
    };

    fetchDepartments();
  }, [backendUrl]);

  // Helper function for processing departments
  const preprocessDepartments = (data) => {
    if (data && Array.isArray(data.data)) {
      return data.data;
    }
    return [];
  };

  // Cleanup to avoid event listeners piling up
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setActionsVisible(false);
        setSelectedRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRowClick = (row) => {
    if (selectedRow?.id === row.id) {
      setSelectedRow(null); // Deselect row
      setActionsVisible(false);
    } else {
      setSelectedRow(row); // Set selected row
      setActionsVisible(true);
    }
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };


  
  const editePopup2 = () => {
    setIsOpenEdit2(!isOpenEdit2);
  };

  /*const handleCloseEditPopup = () => {
    setIsOpenEdit(false); // Ensures the modal is closed
    window.history.replaceState(null, null, "/finance-management/assets");
  };*/

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    if (selectedRow) {
      setIsOpenEdit(true);
    } else {
      alert("Please select a row to edit.");
    }
  };

  const handleRevaluation = () => {
    console.log("Revaluation row: ", selectedRow);
    if (selectedRow) {
      setIsOpenEdit2(true);
    } else {
      alert("Please select a row to edit.");
    }
  };
  // Handle Modal Close
  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedRow(null);
    window.history.replaceState(null, null, "/finance-management/assets"); // Reset URL
  };

  const updateRowStatus = async (status) => {
    if (!selectedRow || !selectedRow.id) {
      console.error("No row selected or missing ID.");
      return;
    }

    const url = `${backendUrl}/assets/assets/checkOrApproveAssets/${selectedRow.id}`;
    console.log("API URL:", url);

    try {
      const response = await axios.put(url, {
        approval_status: status,
        action_by: userId,
      });

      console.log("API Response:", response.data);

      if (response.data.success) {
        setNotification({
          message: `Status updated to "${status}"`,
          type: "success",
        });

        // Update local table data
        setTableData((prevData) =>
          prevData.map((row) =>
            row.id === selectedRow.id
              ? { ...row, approval_status: status }
              : row
          )
        );
        await fetchTableData();
      } else {
        setNotification({
          message: response.data.message || "Failed to update status.",
          type: "error",
        });
      }
    } catch (error) {
      console.error(
        "Error updating status:",
        error.response?.data || error.message
      );
      setNotification({ message: "Error updating status.", type: "error" });
    }
  };

  const handleDeleteClick = async (id) => {
    if (!id) {
      alert("No ID selected for deletion.");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this asset?"
    );
    if (!confirmDelete) return;

    try {
      // Send DELETE request with necessary payload if required
      const response = await axios.put(
        `${backendUrl}/assets/assets/deleteAssets/${selectedRow.id}`,
        {
          deleted_by: userId, // Include user information if required
        }
      );

      if (response.status === 200 || response.status === 204) {
        // Remove the deleted row from the local state
        setTableData((prevData) => prevData.filter((row) => row.id !== id));

        setNotification({
          message: "Asset deleted successfully.",
          type: "success",
        });
        window.history.replaceState(null, null, "/finance-management/assets");
      } else {
        setNotification({
          message: response.data?.message || "Failed to delete the asset.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting asset:", error);
      setNotification({
        message:
          error.response?.data?.message ||
          "An error occurred while deleting the asset.",
        type: "error",
      });
    }
  };

  // Handle action click
  const handleActionClick = (action) => {
    console.log(`Action "${action}" executed for Asset ID: ${selectedRow?.id}`);

    // Map actions to their respective statuses
    const statusMap = {
      Approve: "Approved",
      Draft: "Draft",
      Submitted: "Submitted",
      Reject: "Rejected",
    };

    if (statusMap[action]) {
      updateRowStatus(statusMap[action]);
    }

    setActionsVisible(false);
  };

  const getAvailableActions = (status) => {
    switch (status) {
      case "Draft":
        return ["Submitted", "Reject"];
      case "Submitted":
        return ["Approve", "Reject"];
      case "Rejected":
        return ["Draft"];
      default:
        return [];
    }
  };

  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setActionsVisible(false);
      setSelectedRow(null);
    }
  };
  // Handle Approval Status Change
  const handleApprovalStatusChange = (e) => {
    setApprovalStatus(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  // Handle Category Change
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  // Handle Department Change
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };
  // Handlers for filters
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  const handleItemCodeChange = (e) => {
    setItemCode(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "assets_category_id") {
      fetchParentNames(value);
    }
  };

  const fetchParentNames = async (categoryId) => {
    try {
      const response = await axios.get(
        `${backendUrl}/assets/assets/getAllAssetsNameByCategoryID`,
        { params: { categoryID: categoryId } }
      );
      setParentNames(response.data);
    } catch (error) {
      console.error("Error fetching parent names:", error);
    }
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      alert("Only PDF files are allowed. Please upload a valid PDF.");
      return;
    }
    setFormData((prevDetails) => ({
      ...prevDetails,
      assetsFile: file,
    }));
  };

  // Submit form data
  const handleSubmit = async () => {
    if (!validateFormData()) return;

    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        submissionData.append(key, value);
      }
    });

    // Add extra fields required by the backend
    submissionData.append(
      "isExistingAsset",
      assetType === "existing" ? "Existing" : "New"
    );
    submissionData.append("created_by", userId || "defaultUserId");

    // Debugging: Log the FormData contents
    console.log("Submitting FormData:");
    for (const [key, value] of submissionData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await axios.post(
        `${backendUrl}/assets/assets/addAssets`,
        submissionData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Asset saved successfully:", response.data);
      setNotification({
        message: "Asset saved successfully!",
        type: "success",
      });

      refreshForm();
      fetchTableData();
      

      // Add a timeout to allow the user to see the success message before refreshing
      // Redirect or reload the page properly
      // Refresh the page after a short delay
      // setTimeout(() => {
      //   window.location.reload(); // Refresh the page
      // }, 100);
    } catch (error) {
      console.error("Error saving asset:", error);
      console.error("Server response:", error.response?.data || error.message);
      setNotification({
        message: error.response?.data?.error || "An unexpected error occurred.",
        type: "error",
      });
    }
  };

  const handleAssetTypeChange = (e) => {
    setAssetType(e.target.value);
  };

  const handleAddDisposalClick = () => {
    if (selectedRow && selectedRow.id) {
      navigate(`/finance-management/add-disposal?id=${selectedRow.id}`);
    } else {
      console.error("Selected row or ID is missing");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {notification.message && (
        <div
          className={`${
            notification.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white text-center py-2 rounded mb-4`}
        >
          {notification.message}
        </div>
      )}

      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">Assets</h2>

        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4 w-2/3 mx-auto">
            <div className="w-1/3">
              <label htmlFor="asset_type" className="block text-gray-500 mb-2">
                Asset Type
              </label>
              <select
                id="asset_type"
                name="asset_type"
                className="w-full p-2 border rounded-lg"
                value={assetType}
                onChange={handleAssetTypeChange}
              >
                <option value="new">New Asset</option>
                <option value="existing">Existing Asset</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Updated Section: Input fields with 3 per row */}
      <form action="POST" className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          <div className="w-full">
            <label htmlFor="category_id" className="block text-gray-500 mb-2">
              Asset Category ID
            </label>
            <select
              id="assets_category_id"
              name="assets_category_id"
              className="w-full p-2 border rounded-lg"
              onChange={handleInputChange}
              value={formData.assets_category_id}
            >
              <option value="">-- Select Category ID --</option>
              {categories.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">Item Code*</label>
            <input
              type="text"
              name="item_code"
              value={formData.item_code}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Conditionally render Parent Asset ID */}
          {assetType === "existing" && (
            <div className="w-full">
              <label
                htmlFor="parent_assets_id"
                className="block text-gray-500 mb-2"
              >
                Parent Asset Id
              </label>
              <select
                id="parent_assets_id"
                name="parent_assets_id"
                className="w-full p-2 border rounded-lg"
                onChange={handleInputChange}
                value={formData.parent_assets_id}
              >
                <option value="">-- Select Parent Id --</option>
                {parentNames
                  .filter((parent) => parent.label) // Exclude entries with null labels
                  .map((parent) => (
                    <option key={parent.value} value={parent.value}>
                      {parent.label}
                    </option>
                  ))}
              </select>
            </div>
          )}

          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Acquisition Date*
            </label>
            <input
              type="date"
              name="acquisiton_date"
              value={formData.acquisiton_date}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Acquisition Cost*
            </label>
            <input
              type="number"
              name="acquisition_cost"
              value={formData.acquisition_cost}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              min="0"
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">Supplier*</label>
            <input
              type="text"
              name="supplier"
              value={formData.supplier}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">Asset Name*</label>
            <input
              type="text"
              name="asset_name"
              value={formData.asset_name}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">Description*</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Purchase Invoice ID*
            </label>
            <input
              type="text"
              name="purchase_invoice_id"
              value={formData.purchase_invoice_id}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label
              className="block text-gray-700 mb-2"
              htmlFor="warranty_expire_date"
            >
              Warranty Expire Date*
            </label>
            <input
              type="date"
              name="warranty_expiry_date"
              value={formData.warranty_expiry_date}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Conditionally render Department and Depreciation Rate */}
          {assetType === "new" && (
            <>
              <div className="w-full">
                <label
                  htmlFor="department"
                  className="block text-gray-500 mb-2"
                >
                  Department
                </label>
                <ReactSelect
                  id="department"
                  name="department"
                  className="w-full"
                  options={departments.filter(
                    (dept) => dept.department && dept.department.trim() !== ""
                  )} // Filter out empty departments
                  value={
                    departments
                      ? departments.find(
                          (dept) => dept.department === formData.department
                        )
                      : null
                  }
                  onChange={(selectedOption) => {
                    setFormData((prevDetails) => ({
                      ...prevDetails,
                      department: selectedOption ? selectedOption.value : "",
                    }));
                  }}
                  getOptionLabel={(e) => e.department} // Ensure it's the department name being displayed
                  getOptionValue={(e) => e.department} // Value is the department name
                  isSearchable={true} // Makes it searchable
                  placeholder="Select Department"
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="depreciation_rate"
                  className="block text-gray-700 mb-2"
                >
                  Depreciation Rate*
                </label>
                <input
                  type="text"
                  name="depreciation_rate"
                  value={formData.depreciation_rate}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            </>
          )}
        </div>

        <div className="w-full mt-6">
          <div className="flex">
            <label
              htmlFor="assetsFile"
              className="px-4 py-2 bg-black text-white rounded-md cursor-pointer"
            >
              Upload File
            </label>
            <input
              id="assetsFile"
              type="file"
              name="assetsFile"
              className="hidden"
              onChange={handleFileChange} // This ensures the file is added to formData
            />
          </div>
        </div>

        <span className=""></span>

        <button
          className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </form>

      <div className="flex justify-center border-b-2 mb-4">
        <button
          onClick={() => setActiveTab("AssetsTable")}
          className={`px-8 py-4 text-xl ${
            activeTab === "AssetsTable"
              ? "border-b-4 border-blue-600 text-blue-600 font-bold"
              : "text-gray-700 hover:text-blue-600 hover:border-b-4 hover:border-blue-500"
          } transition-all duration-300`}
        >
          Assets Table
        </button>
        <button
          onClick={() => setActiveTab("DisposalTable")}
          className={`px-8 py-4 text-xl ${
            activeTab === "DisposalTable"
              ? "border-b-4 border-blue-600 text-blue-600 font-bold"
              : "text-gray-700 hover:text-blue-600 hover:border-b-4 hover:border-blue-500"
          } transition-all duration-300`}
        >
          Disposal Table
        </button>
      </div>

      {activeTab === "AssetsTable" && (
        <div>
          <p className="text-3xl  mt-8 flex justify-center font-bold ">
            Assets Table
          </p>

          <div className="mt-5">
            <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
              <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                <div className="w-full md:w-[200px] relative">
                  <label
                    htmlFor="approvalStatus"
                    className="block font-bold mb-2"
                  >
                    Approval Status
                  </label>
                  <select
                    id="approvalStatus"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    value={approvalStatus}
                    onChange={handleApprovalStatusChange}
                  >
                    <option value="">-- Select Approval Status --</option>
                    <option value="Approved">Approved</option>
                    <option value="Draft">Draft</option>
                    <option value="Submitted">Submitted</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>

                <div className="w-full md:w-[200px] relative">
                  <label htmlFor="item_code" className="block font-bold mb-2">
                    Item Code
                  </label>
                  <input
                    id="item_code"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    value={itemCode}
                    onChange={handleItemCodeChange} // Corrected handler
                  ></input>
                </div>

                <div className="w-full md:w-[200px]">
                  <label htmlFor="category" className="block font-bold mb-2">
                    Category
                  </label>
                  <select
                    id="category"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="">-- Select Category --</option>
                    {categories.map((category) => (
                      <option key={category.value} value={category.label}>
                        {category.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-full md:w-[200px]">
                  <label htmlFor="department" className="block font-bold mb-2">
                    Department
                  </label>
                  <select
                    id="department"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                  >
                    <option value="">-- Select Department --</option>
                    {departments.map((dept) => (
                      <option key={dept.department} value={dept.department}>
                        {dept.department}
                      </option>
                    ))}
                  </select>
                </div>

                {/* From Date Filter */}
                <div className="w-full md:w-[200px]">
                  <label htmlFor="from_date" className="block font-bold mb-2">
                    From Date
                  </label>
                  <input
                    type="date"
                    id="start_date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  />
                </div>

                {/* To Date Filter */}
                <div className="w-full md:w-[200px]">
                  <label htmlFor="to_date" className="block font-bold mb-2">
                    To Date
                  </label>
                  <input
                    type="date"
                    id="end_date"
                    value={toDate}
                    onChange={handleToDateChange}
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  />
                </div>
              </div>

              <div ref={actionsRef} className="relative">
                <button
                  className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none"
                  onClick={() => setActionsVisible(!actionsVisible)}
                  disabled={!selectedRow}
                >
                  Actions
                </button>

                {actionsVisible && selectedRow && (
                  <div
                    className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg"
                    style={{ width: "200px" }}
                  >
                    {/* Always show Edit and Delete */}

                    {selectedRow.approval_status !== "Approved" &&
                      selectedRow.approval_status !== "Submitted" && (
                        <button
                          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                          onClick={() => handleEdit(selectedRow.id)}
                        >
                          <FaEdit className="mr-2" />
                          Edit
                        </button>
                      )}
                    {selectedRow.approval_status !== "Approved" &&
                      selectedRow.approval_status !== "Submitted" && (
                        <button
                          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                          onClick={() => handleDeleteClick(selectedRow.id)}
                        >
                          <FaTrash className="mr-2" />
                          Delete
                        </button>
                      )}

                    {selectedRow.approval_status == "Approved" && (
                      <button
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                        onClick={() => handleAddDisposalClick(selectedRow.id)}
                      >
                        <IoTrashBin className="mr-2" />
                        Add Disposal
                      </button>
                    )}
                    {selectedRow.approval_status == "Approved" && (
                      <button
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                        onClick={() => handleRevaluation(selectedRow.id)}
                      >
                        <FaRecycle className="mr-2" />
                        Revaluation
                      </button>
                    )}

                    {/* Render dynamic actions based on the approval_status */}
                    {getAvailableActions(selectedRow.approval_status).map(
                      (action) => (
                        <button
                          key={action}
                          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                          onClick={() => handleActionClick(action)}
                        >
                          {action === "Approve" && <TiTick className="mr-2" />}
                          {action === "Draft" && (
                            <RiDraftFill className="mr-2" />
                          )}
                          {action === "Submitted" && (
                            <HiDocumentCheck className="mr-2" />
                          )}
                          {action === "Reject" && (
                            <IoIosCloseCircleOutline className="mr-2" />
                          )}
                          {action}
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="overflow-x-auto mt-10">
            <table className="w-full bg-white border border-black rounded-lg">
              <thead className="bg-gray-300 ">
                <tr>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                    Asset ID
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Asset Name
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[150px]">
                    Item Code
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Department
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Category Name
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Acquisition Date
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Acquisition Cost
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Current Value
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Asset File
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Supplier
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                    Description
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Approval Status
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Created By
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Depreciation Rate
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Parent Asset Name
                  </th>
                  <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                    Parent Asset ID
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((row) => (
                    <tr
                      key={row.id}
                      className={`cursor-pointer ${
                        selectedRow?.id === row.id ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleRowClick(row)}
                    >
                      {" "}
                      {/* Combine assets_id and index */}
                      <td className="py-2 px-4 text-center border-b whitespace-nowrap w-[200px]">
                        {row.assets_id}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.asset_name}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.item_code}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.department}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.category_name}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.acquisiton_date
                          ? new Date(row.acquisiton_date).toLocaleDateString()
                          : ""}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {parseFloat(row.acquisition_cost).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {parseFloat(row.current_value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        <button
                          className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                          onClick={() => handleFileDownload(row.id)}
                        >
                          Download
                        </button>
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.supplier}
                      </td>
                      <td className="py-2 px-4 text-center border-b whitespace-nowrap w-[200px]">
                        {row.description}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.approval_status}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.created_by}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.depreciation_rate}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.parent_asset_name}
                      </td>
                      <td className="py-2 px-4 text-center border-b">
                        {row.parent_item_code}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={16} className="text-center py-4">
                      No data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Rows per page and pagination */}
          <div className="flex justify-between items-center mt-6 mb-6">
            <div>
              <label htmlFor="rowsPerPage" className="mr-2 text-2xl">
                Rows per page:
              </label>
              <select
                id="rowsPerPage"
                value={rowsPerPage}
                onChange={(e) =>
                  handleRowsPerPageChange(Number(e.target.value))
                }
                className="p-1 border border-gray-400 rounded"
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              {Array.from({ length: pagination.total_pages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)} // Call handlePageChange with the correct page
                  className={`px-3 py-1 rounded ${
                    pagination.current_page === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 hover:bg-gray-300"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>

          {isOpenEdit && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg relative">
                <button
                  type="button"
                  onClick={editePopup}
                  className="absolute text-4xl top-4 right-4 text-gray-500 hover:text-black"
                >
                  <IoIosCloseCircleOutline />
                </button>
                <EditAssets editePopup={editePopup} row={selectedRow} />
              </div>
            </div>
          )}

          {isOpenEdit2 && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg relative">
                <button
                  type="button"
                  onClick={editePopup2}
                  className="absolute text-4xl top-4 right-4 text-gray-500 hover:text-black"
                >
                  <IoIosCloseCircleOutline />
                </button>
                <AssetsRevaluation editePopup={editePopup2} row={selectedRow} />
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === "DisposalTable" && <DisposalTable />}
    </div>
  );
}

export default Assets;
