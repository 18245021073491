import React, { useEffect, useState } from "react";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Manual_report = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableRest, setTableReset] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState("");

  const { hasPermission } = usePermissions();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [items,setItems] = useState()

  const rowsPerPage = 5; // Number of rows per page

  // Calculate the number of pages
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const [seletedValue, setSeletedValue] = useState({
    search_field: "",
    start_date: "",
    end_date: "",
    sample_id: "",
    Expiry_Date: "",
    approval_stage_1: ""
  });

  // Get the current rows for the page
  const currentRows = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );


  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const formatDateForInput = (date) => {
    if (!date) return ""; // Return an empty string if date is invalid
    const formattedDate = new Date(date);
    return isNaN(formattedDate) ? "" : formattedDate.toISOString().slice(0, 10); // Format to 'YYYY-MM-DD'
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Prevent page change if out of bounds
    setCurrentPage(page);
  };

  const handleStartDateChange = (event) => {
    const formattedDate = formatDateForInput(event.target.value);
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (event) => {
    const formattedDate = formatDateForInput(event.target.value);
    setEndDate(formattedDate);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getTableData = async () => {
    try {
      const params = {
        ...seletedValue
      };

      
      const response = await axios.get(
        `${backendUrl}/qulaityDisposals/getSampleDisposalApproval1`,  
        {
          params,
        }
      );

      setTableData(response.data);
    } catch (error) {
      setTableData([]);
      console.error("Error fetching sample details:", error);
    }
  };
 

  const PdfExport = (row) => {
    const doc = new jsPDF();
  
    // Title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 51, 102); // Dark blue
    doc.text(`Manual Sample Disposal Report`, 10, 15);
  
    // Subtitle
    doc.setFontSize(12);
    doc.setTextColor(100);
    doc.text(`Disposal ID: ${row.sample_id}`, 10, 25);
  
    // Divider Line
    doc.setDrawColor(0, 51, 102); // Dark blue
    doc.line(10, 30, 200, 30);
  
    // Section Headers
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0);
  
    let yPosition = 40;
  
    const addDetail = (label, value) => {
      doc.setTextColor(0, 51, 102); // Dark blue
      doc.text(`${label}:`, 10, yPosition);
      doc.setTextColor(50); // Gray
      doc.setFont("helvetica", "normal");
      doc.text(`${value}`, 60, yPosition);
      yPosition += 10;
    };
  
    // Add Sample Details
    addDetail("Sample Type", row.sample_type);
    addDetail("Sample Name", row.sample_name);
    addDetail("Disposed Date", new Date(row.Expiry_Date).toLocaleDateString("en-LK"));
    addDetail("Dispose Requestor", row.dispose_requestor);
    addDetail("Disposal Remark", row.disposal_remark);
  
    // Save the PDF
    doc.save(`${row.sample_id}_disposal_report.pdf`);
  };
  
  
  

    // Handle approval change
    const handleApprovalChange = async (event, row) => {
      const newStatus = event.target.value; // Get the new status from the dropdown
      console.log(row); // Logging the current row
      console.log(newStatus); // Logging the new status
  
      // Update the item's approval status locally
      const updatedItems = tableData.map((currentItem) =>
        currentItem.dispose_id === row.dispose_id // Fix to use row.sample_test_id
          ? { ...currentItem, approval_stage_1: newStatus } // Update the correct field
          : currentItem
      );
      setItems(updatedItems); // Update the local state with new items
  
      // Prepare the request payload
      const requestBody = {
        approval_stage_1: newStatus, // Include the new approval status
        remark_stage_1: "Approval updated",
        approver_stage_1: getCookieValue("employee_fullname"), // Replace with the actual approver ID
      };
  
      try {
        // Send the PUT request to update the approval status on the server
        await axios.put(
          `${backendUrl}/qulaityDisposals/editDisposalApprove1/${row.dispose_id}`, 
          requestBody
        );
        console.log("Approval updated successfully");
      } catch (error) {
        console.error("Error updating approval status:", error);
        // Optionally, show an error message to the user
      }
  
      getTableData();
    };

  useEffect(() => {
    getTableData();
  }, [seletedValue]);

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
            Manual Disposal Report Approval One
            </p>
          </div>
        </div>

        {/* Controls with Add Button */}
        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/* Search Box with Manual Width */}
              <div className="w-full mt-6 md:w-[300px]">
                <input
                  type="text"
                  placeholder="Sample Testing ID"
                  value={seletedValue.search_field}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Start Date Selector with Manual Width */}
              <div className="w-full md:w-[200px]">
                <label className="block mb-1 text-gray-700">Start Date</label>
                <input
                  type="date"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={seletedValue.start_date}
                  onChange={handleChange}
                />
              </div>

              {/* End Date Selector with Manual Width */}
              <div className="w-full md:w-[200px]">
                <label className="block mb-1 text-gray-700">End Date</label>
                <input
                  type="date"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={seletedValue.end_date}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-14">
        {/* TailwindCSS Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample ID
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Submission Date
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Report
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Status
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentRows.map((row) => (
              <tr key={row.dispose_id}>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_id}
                </td>

                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {new Date(row.Expiry_Date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long", // Use "short" for abbreviated month names
                    day: "2-digit",
                  })}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {hasPermission(8014) && (
                   <button
                      className="p-2 text-blue-500 rounded hover:bg-blue-100"
                      onClick={() => PdfExport(row)}
                    >
                      Download PDF
                    </button>
                  )}
                </td>

                <td
                  className={`px-6 py-4 text-sm whitespace-nowrap ${
                    row.approval_stage_1 === "NOT APPROVED"
                      ? "text-black-500" 
                      : row.approval_stage_1 === "REJECTED"
                      ? "text-red-500" 
                      : row.approval_stage_1 === "APPROVED"
                      ? "text-green-500" 
                      : "text-gray-500" 
                  }`}
                >
                  {row.approval_stage_1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md">
                  <select
                    value={row.approved_status_1 || ""}
                    onChange={(e) => handleApprovalChange(e, row)}
                    className={`px-3 py-1 rounded-full border outline-none transition-colors duration-200
                      ${
                        row.approved_status_1 === "APPROVED"
                          ? "bg-green-100 text-green-700 border-green-400"
                          : row.approved_status_1 === "REJECTED"
                          ? "bg-red-100 text-red-700 border-red-400"
                          : "bg-gray-100 text-gray-700 border-gray-300" // Default ash color
                      }
                      hover:bg-gray-200 focus:ring focus:ring-gray-300
                    `}
                  >
                    <option value="" disabled>
                      Select the Status
                    </option>
                    <option value="APPROVED" className="text-green-700">APPROVED</option>
                    <option value="REJECTED" className="text-red-700">REJECTED</option>
                  </select>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={`px-3 py-1 mx-1 text-sm rounded ${
              currentPage === i + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Manual_report;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}
