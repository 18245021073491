/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const DialogEditForm = ({ onClose, tableDate, item }) => {
  const [supplierNumber, setSupplierNumber] = useState(item.supplier_id);
  const [supplierName, setSupplierName] = useState(item.supplier_name);
  const [address, setAddress] = useState(item.supplier_address);
  const [phoneNo, setPhoneNo] = useState(item.supplier_contact_no);
  const [faxNo, setFaxNo] = useState(item.supplier_fax);
  const [email, setEmail] = useState(item.supplier_email);
  const [vatNo, setVatNo] = useState(item.supplier_status);
  const [suppliernic, setsuppliernic] = useState(item.supplier_nic);
  const [selectedCategories, setSelectedCategories] = useState(
    item.supplier_category_ids || []
  );
  const [categoryOptions, setCategoryOptions] = useState([]); // Store filtered categories
  const [date, setDate] = useState(new Date());
  const { REACT_APP_API_ENDPOINT } = process.env;
  const userId = Cookies.get("employee_no");
  // Fetch "RAW" Categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
        );
        const data = await response.json();

        // ✅ Filter only "RAW" categories
        const filteredData = data.filter(
          (item) => item.item_category_name === "RAW"
        );

        setCategoryOptions(filteredData);
      } catch (error) {
        console.error("Error fetching Item Category data:", error);
      }
    };

    fetchCategories();
  }, []);

  // Handle multiple category selection
  const handleCategoryChange = (event) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedCategories(selectedValues);
  };

  // Submit updated supplier data
  const handleSubmit = async () => {
    const data = {
      supplier_id: supplierNumber,
      supplier_name: supplierName,
      supplier_nic: suppliernic,
      supplier_address: address,
      supplier_contact_no: phoneNo,
      supplier_fax: faxNo,
      supplier_email: email,
      supplier_status: "ACTIVE",
      supplier_category_ids: selectedCategories,
      supplier_update_date: date.toLocaleDateString("en-GB"),
      supplier_update_user_id: userId,
    };

    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/update/${supplierNumber}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Successfully updated Supplier Data");
        tableDate(); // Refresh the table
        onClose(); // Close the modal
      }
    } catch (error) {
      console.error("Error updating supplier:", error);
      alert("Unable to update Supplier Data");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>

        <div className="flex justify-center items-center h-[80%]">
          <div className="bg-dialogBoxbg rounded-[19px] w-[90%] h-[95%] flex-shrink-0">
            <div className="flex justify-center items-center h-[100%]">
              <div className="bg-dialogBoxbg rounded-[19px] w-[100%] h-[100%] flex-shrink-0">
                <div className="grid grid-cols-4 mt-10 gap-4">
                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Supplier Number:
                  </label>
                  <input
                    type="text"
                    value={supplierNumber}
                    className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    readOnly
                  />

                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Supplier Name:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    value={supplierName}
                    onChange={(e) => setSupplierName(e.target.value)}
                  />

                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Supplier NIC:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    value={suppliernic}
                    onChange={(e) => setsuppliernic(e.target.value)}
                  />

                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Address:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />

                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Phone Number:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />

                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Email Address:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  {/* ✅ Multi-Select Dropdown for Categories */}
                  <label className="ml-4 text-black font-poppins font-bold text-xl ">
                    Category:
                  </label>
                  <select
                    multiple
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
                  >
                    {categoryOptions.map((category) => (
                      <option
                        key={category.sub_category_id}
                        value={category.sub_category_id}
                      >
                        {category.item_category_name} -{" "}
                        {category.item_sub_category_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="justify-center mt-10">
                  <button
                    className="text-white bg-primary rounded-[22px] font-bold min-w-24 text-[22px]"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sticky footer */}
      </div>
    </div>
  );
};

export default DialogEditForm;
