import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../Assets/icons/csv.png";
import usePermissions from "../../../components/permissions/permission";
import Papa from "papaparse";

const BranchItemReturnReleasedRequest = () => {
  const [items, setItems] = useState([]);
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Default to 10 items per page
  const [totalPages, setTotalPages] = useState(1);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [status, setStatus] = useState("ACCEPTED");

  useEffect(() => {
    fetchItemData();
  }, [currentPage, searchQuery, searchMonth, searchYear]);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/BracnReturn/requested-return?status=${status}&page=${currentPage}&pageSize=${itemsPerPage}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setItems(data.data);
      setTotalPages(data.totalPages); // Update total pages from API response
      console.log("Data:", data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  const HandleOnClick = (itemReq) => {
    navigate("/inventory/branch-item-return-req-released-view", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Function
  const goToPage = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Export to CSV
  const exportToCSV = () => {
    const csvData = items.map((itemReq) => ({
      "Return Requested ID": itemReq.return_id,
      "Return Requested Date": new Date(itemReq.return_at)
        .toISOString()
        .split("T")[0],
      "Branch ID": itemReq.branch_id,
      Status: itemReq.status,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "BranchItemReturnReleasedRequest.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Released Branch Item Return Requests
      </p>

      {/* Search Filters */}
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <input
            type="text"
            placeholder="Search by ID, Branch"
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-[200px] sm:w-[300px] h-10 rounded-full border border-gray-400 px-2 py-1 ml-6 mt-4"
          />

          <select
            value={searchMonth}
            onChange={handleMonthChange}
            className="w-[200px] sm:w-[300px] h-10 rounded-full border border-gray-400 px-2 py-1 ml-6 mt-4"
          >
            <option value="">Filter By Month</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>

          <select
            onChange={handleYearChange}
            className="w-[200px] sm:w-[300px] h-10 rounded-full border border-gray-400 px-2 py-1 ml-6 mt-4"
          >
            <option value="">Filter By Year</option>
            {Array.from(
              { length: 20 },
              (_, i) => new Date().getFullYear() - i
            ).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px]">
        <table className="table-auto border-none w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Return ID</th>
              <th className="text-primary px-6 py-4">Requested Date</th>
              <th className="text-primary px-6 py-4">Branch ID</th>
              <th className="text-primary px-6 py-4">Status</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {items.map((itemReq) => (
              <tr key={itemReq._id}>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.return_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.return_at
                    ? new Date(itemReq.return_at).toISOString().split("T")[0]
                    : "No Return Date"}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.branch_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.status}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {hasPermission(2311) && (
                    <button
                      className="bg-gray-300 p-2 rounded-md w-[200px]"
                      onClick={() => HandleOnClick(itemReq)}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index + 1)}
            className={`px-4 py-2 mx-1 rounded-md ${
              currentPage === index + 1 ? "bg-gray-300" : "bg-white"
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default BranchItemReturnReleasedRequest;
