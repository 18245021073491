import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";

const Debtors = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const backUrl = process.env.REACT_APP_BACKEND_URL;
  const [debtors, setDebtors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState({
    customer_id: "",
    customer_name: "",
    customer_category: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
    total: 0,
    total_pages: 0,
  });

  // Fetch debtors data with filters and pagination
  const fetchDebtors = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/receivable/salesInvoice/getDebtors`,
        {
          params: {
            ...filters,
            page: pagination.page,
            page_size: pagination.page_size,
          },
        }
      );
      setDebtors(response.data.data);
      setPagination({
        ...pagination,
        total: response.data.total,
        total_pages: response.data.total_pages,
      });
    } catch (error) {
      console.error("Error fetching debtors:", error);
    }
  };

  // Handle filter and pagination changes
  const handleFilterChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta; 
    setFilters((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : "", 
    }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (e) => {
    setPagination((prev) => ({
      ...prev,
      page_size: parseInt(e.target.value, 10),
      page: 1,
    }));
  };

  const handleCustomerClick = (customerId) => {
    navigate(`/finance-management/account-receivable/sub-ledger/${customerId}`);
  };

  useEffect(() => {
    fetchDebtors();
  }, [filters, pagination.page, pagination.page_size]);

  // Fetch dropdown data for customers
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(
          `${backUrl}/customer`
        );
        console.log("API Response for Suppliers:", response.data); // Log the response data
        const customerData = Array.isArray(response.data)
          ? response.data
          : response.data.data || []; // Adjust if response data has nested structure
          setCustomers(
            customerData.map((customer) => ({
            name: customer.customer_name, // Adjust field names as per the API response
          }))
        );
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };
    
    fetchCustomers();
  }, [backUrl]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <h2 className="text-3xl font-semibold mb-4">Debtors</h2>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-5">
        <input
          type="text"
          name="customer_id"
          placeholder="Customer ID"
          value={filters.customer_id}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="customer_name"
          placeholder="Customer Name"
          value={filters.customer_name}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <ReactSelect
          options={customers.map((customer) => ({
            value: customer.name,
            label: customer.name,
          }))}
          name="customer_name"
          onChange={handleFilterChange}
          value={
            filters.customer_name
              ? {
                  value: filters.customer_name,
                  label: customers.find((c) => c.name === filters.customer_name)?.name || filters.customer_name,
                }
              : null
          }
          isClearable
          placeholder="Customer Name"
        />



        <input
          type="text"
          name="customer_category"
          placeholder="Customer Category"
          value={filters.customer_category}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
      </div>

      {/* Debtors Table */}
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="px-4 py-2 border">Customer ID</th>
            <th className="px-4 py-2 border">Customer Name</th>
            <th className="px-4 py-2 border">Customer Category</th>
            <th className="px-4 py-2 border">Total Unsettled Amount</th>
          </tr>
        </thead>
        <tbody>
          {debtors.map((debtor) => (
            <tr key={debtor.customer_id}>
              <td
                className="px-4 py-2 border text-blue-500 cursor-pointer no-underline hover:underline ..."
                onClick={() => handleCustomerClick(debtor.customer_id)}
              >
                {debtor.customer_id}
              </td>
              <td className="px-4 py-2 border">{debtor.customer_name}</td>
              <td className="px-4 py-2 border">{debtor.customer_category}</td>
              <td className="px-4 py-2 border">
                {debtor.total_unsettled_amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <div>
          Page {pagination.page} of {pagination.total_pages}
        </div>
        <div className="flex items-center gap-2">
          <label htmlFor="pageSize">Rows per page:</label>
          <select
            id="pageSize"
            value={pagination.page_size}
            onChange={handlePageSizeChange}
            className="border p-1 rounded"
          >
            {" "}
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => handlePageChange(Math.max(1, pagination.page - 1))}
            className="border px-3 py-1 rounded"
            disabled={pagination.page === 1}
          >
            Previous
          </button>
          <button
            onClick={() =>
              handlePageChange(
                Math.min(pagination.total_pages, pagination.page + 1)
              )
            }
            className="border px-3 py-1 rounded"
            disabled={pagination.page === pagination.total_pages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Debtors;
