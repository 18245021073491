/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./inventoryPurchase.css";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import "jspdf-autotable";
import usePermissions from "../../../components/permissions/permission";
import { useNavigate, useLocation } from "react-router-dom";

export default function InventoryPurchaseViewItems() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const userId = Cookies.get("employee_no");
  const location = useLocation();
  const selectedRecord = location.state?.selectedRecord || {};
  const [poItemsData, setPoItemsData] = useState(selectedRecord.items || []);
  console.log("selelcted rec1", selectedRecord);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();
  const [Po, setPo] = useState([]);

  const [stores, setStoreOptions] = useState([]);

  const fetchProItemData = async () => {
    try {
      // Extract item IDs from procurement_items
      const itemIds = selectedRecord.items.map((item) => item.item_id);

      // Fetch data for each item ID
      const fetchPromises = itemIds.map((itemId) =>
        fetch(`${REACT_APP_API_ENDPOINT}/v1/inventory/item/item/${itemId}`)
      );

      // Await all fetches
      const responses = await Promise.all(fetchPromises);

      // Parse all responses
      const dataPromises = responses.map((response) => response.json());
      const dataArray = await Promise.all(dataPromises);

      // Prepare table data based on fetched data
      const newTableData = dataArray.map((itemData, index) => {
        const procurementItem = selectedRecord.items[index];
        return {
          item_id: itemData.item_id,
          item_name: itemData.item_name,
          item_description: itemData.item_description,
          item_measure_unit: itemData.item_measure_unit,
          item_qty: procurementItem.item_qty || 0, // Use item_qty from procurement_items if available
          item_received: procurementItem.item_received || 0,
          item_unit_cost: procurementItem.item_unit_cost || 0,
          item_line_amount: procurementItem.item_line_amount || 0,
          item_qty_to_receive: procurementItem.qty_to_request || 0,
          item_qty_to_received: procurementItem.item_qty_to_received || 0,
          item_location: procurementItem.rack_location || "",
          salable: procurementItem.salable || true,
          item_supQty: procurementItem.supplied_qty || 0,
          remark: procurementItem.remark || "",
          storeId: procurementItem.store_id || "",
        };
      });

      // Update tableData state
      setPoItemsData(newTableData);
      console.log("po Data", newTableData);
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    if ((selectedRecord.items || []).length > 0) {
      fetchProItemData();
    }
  }, [selectedRecord.items]);

  const SubmitHandler = async () => {
    // Construct the post body based on poItemsData
    const postData = {
      create_by: userId, // Assuming the user ID is static or fetched from some state
      po_id: selectedRecord.po_id, // Assuming poItemsData contains po_id
      grn_items: poItemsData.map((item) => ({
        item_id: item.item_id,
        supplied_qty: item.item_supQty,
        store_id: item.storeId,
        salable: item.selling_item, // Either 'yes' or 'no'
        remark: item.remark,
        rack_location: item.item_location,
        status: "Received",
      })),
    };

    console.log("Post Data: ", postData);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/supplyChain/grn/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );
      const responseData = await res.json();
      console.log("Response: ", responseData);

      if (res.status === 200 || res.status === 201) {
        alert("Inventory Added Successfully");
        navigate(-1);
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Inventory Already Exist");
      } else if (error.response.status === 400) {
        alert("Inventory Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Inventory Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      } else {
        alert("An unexpected error occurred");
      }
    }
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  // const PdfExport = () => {
  //   const doc = new jsPDF();

  //   // Define the table columns and rows
  //   const columns = [
  //     "Item Name",
  //     "Purchase Order Qty",
  //     "Supplied Qty",
  //     "Store",
  //     "Location",
  //     "Unit",
  //     "Salable",
  //     "Remark",
  //   ];

  //   const rows = poItemsData.map((item) => [
  //     item.item_name,
  //     item.item_qty_to_receive,
  //     item.item_supQty,
  //     item.storeId,
  //     item.item_location,
  //     item.item_measure_unit,
  //     item.salable,
  //     item.remark,
  //   ]);

  //   // Add title
  //   doc.text("Inventory Purchase View Items ", 14, 10);

  //   // Generate the PDF table
  //   doc.autoTable({
  //     head: [columns],
  //     body: rows,
  //     startY: 20,
  //   });

  //   // Save the PDF
  //   doc.save("InventoryPurchaseViewItems.pdf");
  // };

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Inventory Purchase (GRN)", 14, 20);

    doc.setFontSize(12);
    doc.text(`Purchase Order ID: ${selectedRecord.po_id}`, 14, 30);
    doc.text(`Supplier: ${selectedRecord.grn_number}`, 14, 40);
    doc.text(`PO Date: ${selectedRecord.create_at}`, 14, 50);

    const tableColumn = [
      "Item Name",
      "Purchase Order Qty",
      "Supplied Qty",
      "Store",
      "Location",
      "Unit",
      "Salable",
      "Remark",
    ];

    const tableRows = [];

    poItemsData.forEach((item) => {
      const rowData = [
        item.item_name,
        item.item_qty_to_receive,
        item.item_supQty,
        stores.find((store) => store.value === item.storeId)?.label || "N/A", // Lookup store name
        item.item_location,
        item.item_measure_unit,
        item.selling_item === "true" ? "True" : "False",
        item.remark,
      ];
      tableRows.push(rowData);
    });

    // Adding table to the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60, // Position the table below the order details
    });

    doc.save("purchase_order_items.pdf");
  };

  const HandleBack = (e) => {
    navigate(-1);
  };

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Inventory Purchase (GRN) History Log</p>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Purchase Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Purchase Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.po_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">GRN No :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.grn_number}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">PO Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {new Date(selectedRecord.create_at).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Purchasing Items</p>

      <div className="w-[100%] flex items-center justify-center my-10 ">
        <div className="bg-[#F3F8FF] w-full min-h-96  rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[300px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Purchase Order Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Supplied Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Location</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Unit</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Salable</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remark</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="w-full h-[200px] flex flex-col gap-y-10">
            {poItemsData.length > 0
              ? poItemsData.map((item, index) => (
                  <div className="w-full  flex items-center justify-around">
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[300px] p1 text-[#656565]  text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565]  text-center">
                      {item.item_qty_to_receive}
                    </p>
                    <p className="w-[160px] p1 text-[#656565]  text-center">
                      {item.item_supQty}
                    </p>
                    <p className="w-[150px] p1 text-[#656565]  text-center">
                      {item.storeId}
                    </p>
                    <p className="w-[150px] p1 text-[#656565]  text-center">
                      {item.item_location}
                    </p>
                    <p className="w-[120px] p1 text-[#656565]  text-center">
                      {item.item_measure_unit}
                    </p>
                    <p className="w-[120px] p1 text-[#656565]  text-center">
                      {item.salable === 1 ? "NO" : "Yes"}
                    </p>
                    <p className="w-[170px] p1 text-[#656565]  text-center">
                      {item.remark}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={exportToPDF}
        >
          print
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={HandleBack}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
